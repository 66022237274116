.contact-picker {
	box-shadow: 0 0 10px 2px rgb(0 0 0 / 0.1);
	transition: box-shadow 196ms ease-out;
	z-index: 99;
}

@media (width < 64rem) {
	.contact-picker {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	.contact-picker:hover {
		box-shadow: 0 0 10px 2px rgb(0 0 0 / 0.25);
	}
}

@media (width >= 64rem) {
	.contact-picker {
		box-shadow: none;
		position: absolute;
		top: 60px;
		right: 0;
	}
}

@supports (position: sticky) {
	@media (width >= 64rem) {
		.contact-picker {
			display: block;
			float: right;
			margin-bottom: 160px;
			overflow: visible;
			position: sticky;
			width: 0;
			height: 0;
		}
	}
}

@media (width < 64rem) {
	.contact-picker[open] {
		transform: translateY(0);
	}
}

.contact-picker__button {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-white);
	border: 0;
	box-sizing: border-box;
	color: var(--color-purple);
	cursor: pointer;
	font-family: var(--font-serif);
	font-size: 1rem;
	list-style: none;
	padding: 23px;
	text-align: center;
	width: 100%;
}

.contact-picker__button:hover {
	color: var(--color-blue);
}

.contact-picker__button::-webkit-details-marker {
	display: none;
}

@media (width >= 64rem) {
	.contact-picker__button {
		border-radius: 7rem;
		box-shadow:
			5px 8px 20px 2px rgb(0 0 0 / 0.15),
			inset -5px -8px 12px 2px rgb(0 0 0 / 0.075);
		font-size: 1.125rem;
		padding: 38px 35px;
		position: absolute;
		top: 0;
		right: 60px;
		transform: rotate(-10deg);
		transition: box-shadow 196ms ease-out;
		width: 7rem;
		height: 7rem;
	}
}

@media (width >= 86.25rem) {
	.contact-picker__button {
		border-radius: 8rem;
		font-size: 1.25rem;
		width: 8rem;
		height: 8rem;
	}
}

.contact-picker__button:active,
.contact-picker__button:focus {
	box-shadow: 0 0 10px 2px rgb(0 0 0 / 0.1);
	color: var(--color-purple);
}

.t-jac .contact-picker__button:active,
.t-jac .contact-picker__button:focus {
	color: var(--color-orange);
}

@media (width >= 64rem) {
	.contact-picker__button:active,
	.contact-picker__button:focus {
		box-shadow:
			5px 8px 20px 2px rgb(0 0 0 / 0.15),
			inset -5px -8px 12px 2px rgb(0 0 0 / 0.075);
	}
}

.t-jac .contact-picker__button {
	color: var(--color-orange);
}

@media (width >= 64rem) {
	.contact-picker__button:hover {
		box-shadow:
			5px 8px 20px 2px rgb(0 0 0 / 0.25),
			inset -5px -8px 12px 2px rgb(0 0 0 / 0.15);
	}
}

.contact-picker[open] .contact-picker__button {
	color: var(--color-blue);
}

.contact-picker__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (width < 64rem) {
	.contact-picker__list {
		background-color: var(--color-white);
		display: flex;
		padding: 0 3%;
		justify-content: space-between;
		align-items: center;
		position: relative;
		text-align: center;
		height: 4rem;
		z-index: 3;
	}
}

@media (width >= 64rem) {
	.contact-picker__list {
		box-sizing: border-box;
		padding-bottom: 1rem;
		pointer-events: none;
		position: absolute;
		right: 86px;
		bottom: 100%;
	}
}

@media (width >= 86.25rem) {
	.contact-picker__list {
		right: 92px;
	}
}

@media (width >= 64rem) {
	.contact-picker[open] .contact-picker__list {
		padding-top: 1.5rem;
		padding-bottom: 0;
		pointer-events: auto;
		top: 7rem;
		bottom: auto;
	}
}

@media (width >= 86.25rem) {
	.contact-picker[data-list-down] .contact-picker__list {
		top: 8rem;
	}
}

@media (width < 64rem) {
	.contact-picker__list__item {
		flex-basis: 25%;
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0;
	}
}

@media (width >= 64rem) {
	.contact-picker__list__item {
		display: block;
		opacity: 0;
		transform: translateY(-1rem);
		transition:
			opacity 96ms linear,
			transform 224ms ease-out;
	}
}

@media (width >= 64rem) {
	.contact-picker__list__item:last-child {
		margin-bottom: 0;
	}
}

@media (width >= 64rem) {
	.contact-picker[open] .contact-picker__list__item {
		opacity: 1;
		transform: translateY(0);
	}
}

.contact-picker__list__link {
	box-sizing: border-box;
	display: block;
	padding: 0.5rem 0.5rem;
}

@media (width < 64rem) {
	.contact-picker__list__link {
		text-align: center;
		width: 100%;
	}
}

.contact-picker__list__button {
	background-color: var(--color-white);
	border: 1px solid var(--color-grey-light);
	border-radius: 2rem;
	box-sizing: border-box;
	display: block;
	position: relative;
	text-decoration: none;
	width: 2rem;
	height: 2rem;
}

.contact-picker__list__button .icon {
	fill: var(--color-purple);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: fill 96ms linear;
	width: 0.875rem;
	height: 0.875rem;
}

.t-jac .contact-picker__list__button .icon {
	fill: var(--color-orange);
}

.contact-picker__list__link:hover .contact-picker__list__button .icon {
	fill: var(--color-blue);
}

@media (width < 64rem) {
	.contact-picker__list__button {
		margin: 0 auto;
	}
}

.contact-picker__list__label {
	background-color: var(--color-white);
	color: var(--color-blue);
	display: inline-block;
	font-family: var(--font-serif);
	font-size: 0.875rem;
	font-weight: 500;
	opacity: 0;
	padding: 0.075rem 0.5rem;
	pointer-events: none;
	position: absolute;
	text-align: right;
	top: 50%;
	right: 100%;
	transform: translate(1rem, -50%);
	transition:
		opacity 96ms linear,
		transform 224ms ease-out;
	width: max-content;
}

@media (width < 64rem) {
	.contact-picker__list__label {
		display: none;
	}
}

.contact-picker__list__link:hover .contact-picker__list__label {
	opacity: 1;
	transform: translate(-0.5rem, -50%);
}

.contact-picker__list__link:focus-visible .contact-picker__list__label {
	opacity: 1;
	transform: translate(-0.5rem, -50%);
}
