.usp {
	background-color: var(--color-blue);
	box-sizing: border-box;
	color: var(--color-white);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	height: 100%;
}

.usp.usp--muted,
.usp-group__item:last-child .usp {
	background-color: var(--color-blue-light);
	color: var(--color-blue);
}

.usp__title,
.usp__content {
	box-sizing: border-box;
	display: block;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	width: 100%;
	max-width: 420px;
}

@media (width >= 64rem) {
	.usp__title,
	.usp__content {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

@media (width >= 86.25rem) {
	.usp__title,
	.usp__content {
		padding-right: 3rem;
		padding-left: 3rem;
	}
}

.usp__title {
	color: currentcolor;
	font-family: var(--font-serif);
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.3;
	margin-bottom: 1.5rem;
	padding-top: 2rem;
	min-height: 3.875rem;
}

@media (width >= 64rem) {
	.usp__title {
		padding-top: 2.75rem;
	}
}

@media (width >= 86.25rem) {
	.usp__title {
		padding-top: 3.75rem;
	}
}

.usp__content {
	color: currentcolor;
	font-size: 0.875rem;
	margin-bottom: 3.75rem;
}

.usp__content p {
	font-size: inherit;
}

.usp__action {
	background-color: var(--color-white);
	color: var(--color-purple);
	display: block;
	font-family: var(--font-serif);
	font-weight: 500;
	margin-top: auto;
	padding: 1rem 2rem;
	text-decoration: none;
	transition:
		color 96ms linear,
		background-color 96ms linear;
	width: max-content;
}

.t-jac .usp__action {
	color: var(--color-orange);
}

.usp__action:hover {
	background-color: var(--color-purple);
	color: var(--color-white);
}

.t-jac .usp__action:hover {
	background-color: var(--color-orange);
}
