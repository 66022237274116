.post-actions__row {
	border-bottom: 1px solid var(--color-grey-light);
	padding: 1rem 0;
}

@media (width >= 48rem) {
	.post-actions__row {
		padding: 2rem 0;
	}
}

.post-actions__row:first-child {
	border-top: 1px solid var(--color-grey-light);
}

.post-actions__row a {
	color: var(--color-purple);
}
