.share {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}

.share__label {
	margin-right: 1rem;
}

.share__button,
.share__item button {
	background: none;
	border: 0;
	box-shadow: none;
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0 0.75rem 0 0;
	padding: 0;
}

.share__button .icon,
.share__item button .icon {
	display: block;
	transition: fill 96ms linear;
	width: 1rem;
	height: 1rem;
}

.share__button:hover,
.share__item button:hover {
	cursor: pointer;
}

.share__button:hover .icon,
.share__item button:hover .icon {
	fill: var(--color-purple);
}

.t-jac .share__button:hover .icon,
.t-jac .share__item button:hover .icon {
	fill: var(--color-orange);
}
