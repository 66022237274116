.accordion {
}

.accordion__item {
}

.accordion__item:not(:first-child) {
	border-top: 1px solid var(--color-grey-light);
}

.accordion__heading {
	margin: 0;
}

.accordion__trigger {
	-mrh-resets: button;
	color: var(--color-purple);
	width: 100%;
}

.t-jac .accordion__trigger {
	color: var(--color-orange);
}

html:not(.no-js) .accordion__trigger:focus,
html:not(.no-js) .accordion__trigger:hover {
	color: var(--color-black);
	cursor: pointer;
}

.accordion__title {
	color: currentColor;
	display: block;
	font-family: var(--font-serif);
	font-size: 1.125rem;
	line-height: 1.33;
	margin: 0;
	padding: 1em 2em 1em 0;
	pointer-events: none;
	position: relative;
	text-align: left;
	transition: color 96ms linear;
}

@media (width >= 48rem) {
	.accordion__title {
		font-size: 1.25rem;
	}
}

@media (width >= 64rem) {
	.accordion__title {
		padding-right: 20%;
	}
}

@media (width >= 86.25rem) {
	.accordion__title {
		padding-right: 30%;
	}
}

html.no-js .accordion__title {
	cursor: text;
	pointer-events: auto;
	user-select: text;
}

.accordion__title::after {
	content: "+";
	display: block;
	font-size: 1.875rem;
	font-weight: 400;
	line-height: 1;
	pointer-events: none;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	transform-origin: center;
	transition: transform 96ms linear;
}

.accordion__trigger[aria-expanded="true"] .accordion__title::after {
	transform: translateY(-50%) rotate(45deg);
}

html.no-js .accordion__title::after {
	display: none;
}

.accordion__panel {
	padding-top: 0.5rem;
	padding-bottom: 1.375em;
}

html.no-js .accordion__panel[hidden] {
	display: block;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.accordion__panel__inner .embedded-media {
	margin-block-end: 1rem;
}
