.navigation-overlay {
	background-color: var(--color-blue-light);
	width: 100%;
	min-height: 100%;
}

.navigation-overlay__header {
	background-color: var(--color-blue);
	min-height: 3.5rem;
}

.navigation-overlay__header__wrapper {
	width: 100%;
}

.navigation-overlay__logo {
	margin-block: 1rem;
	position: absolute;
	top: 0;
	left: 3.75%;
	width: 5rem;
	height: 1.5rem;
}

@media (width >= 48rem) {
	.navigation-overlay__logo {
		left: 3%;
	}
}

.t-jac .navigation-overlay__logo {
	margin-block: 0.5em;
	width: 3.75rem;
	height: 2.625rem;
}

.navigation-overlay__logo a {
	display: block;
}

.navigation-overlay__logo a img {
	display: block;
	width: 100%;
	height: auto;
}

/* #region Menu toggle */
.navigation-overlay__menu-toggle {
	position: absolute;
	top: 0.3125rem;
	right: calc(3.75% - 0.625rem);
	width: 2.75rem;
	height: 2.75rem;
}

@media (width >= 48rem) {
	.navigation-overlay__menu-toggle {
		right: calc(3% - 0.625rem);
	}
}

.navigation-overlay .menu-toggle > span {
	background-color: var(--color-white);
}

.navigation-overlay .menu-toggle > span:nth-child(1) {
	top: 50%;
	transform: rotate(45deg);
}

.navigation-overlay .menu-toggle > span:nth-child(2) {
	opacity: 0;
}

.navigation-overlay .menu-toggle > span:nth-child(3) {
	top: 50%;
	transform: rotate(-45deg);
}

/* #endregion Menu toggle */

/* #region Primary Navigation */
.navigation-overlay__main__primary {
	color: var(--color-white);
	font-size: 1.125rem;
	font-weight: 400;
	padding-top: 3.5rem;
}

.navigation-overlay__main__primary__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-overlay__main__primary__item.navigation-overlay__main__primary__item--active {
	background-color: var(--color-purple-shaded);
}

.t-jac .navigation-overlay__main__primary__item.navigation-overlay__main__primary__item--active {
	background-color: var(--color-orange-shaded);
}

.navigation-overlay__main__primary__title {
	border-top: 1px solid rgb(255 255 255 / 0.25);
	color: currentcolor;
	display: block;
	position: relative;
	text-decoration: none;
}

.t-jac .navigation-overlay__main__primary__title {
	color: var(--color-white);
}

.navigation-overlay__main__primary__item--active .navigation-overlay__main__primary__title {
	color: var(--color-white);
}

.t-jac .navigation-overlay__main__primary__item--active .navigation-overlay__main__primary__title {
	color: var(--color-white);
}

.navigation-overlay__main__primary__title__label {
	box-sizing: border-box;
	color: currentcolor;
	display: block;
	padding: 0.75rem 0 0.75rem 3.75%;
	position: relative;
	z-index: 1;
}

@media (48rem <= width < 64rem) {
	.navigation-overlay__main__primary__title__label {
		padding: 0.75rem 0 0.75rem 3%;
	}
}

.navigation-overlay__main__primary__submenu {
	background-color: var(--color-purple);
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0.5rem 3.75%;
}

@media (width >= 48rem) {
	.navigation-overlay__main__primary__submenu {
		padding: 0.5rem 3%;
	}
}

.t-jac .navigation-overlay__main__primary__submenu {
	background-color: var(--color-orange);
}

.navigation-overlay__main__primary__submenu__item a {
	color: var(--color-white);
	display: block;
	font-size: 0.875rem;
	padding: 0.5rem 0 0.5rem 1.125rem;
	position: relative;
	text-decoration: none;
}

.navigation-overlay__main__primary__submenu__item--active a::before {
	background-color: var(--color-white);
	border-radius: 6px;
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 6px;
	height: 6px;
}

/* #endregion Primary Navigation */

/* #region Meta Navigation */
.navigation-overlay__meta__menu {
	width: 100%;
}

.navigation-overlay__meta__menu__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-overlay__meta__menu__list a:hover {
	color: var(--color-purple);
}

.navigation-overlay__meta__menu__item:not(:last-child) {
	position: relative;
}

.navigation-overlay__meta__menu__title {
	border-bottom: 1px solid rgb(27 91 154 / 0.25);
	color: currentcolor;
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
}

.navigation-overlay__meta__menu__title__label {
	box-sizing: border-box;
	color: currentcolor;
	color: var(--color-blue);
	display: block;
	padding: 0.75rem 0 0.75rem 3.75%;
	position: relative;
	z-index: 1;
}

.navigation-overlay__meta__menu__title:hover .navigation-overlay__meta__menu__title__label {
	color: var(--color-purple);
}

.navigation-overlay__meta__menu__item--active .navigation-overlay__meta__menu__title__label {
	background-color: rgb(27 91 154 / 0.075);
}

@media (48rem <= width < 64rem) {
	.navigation-overlay__meta__menu__title__label {
		padding: 0.75rem 0 0.75rem 3%;
	}
}

.navigation-overlay__meta__menu__item--highlighted:not(.navigation-overlay__meta__menu__item--active) .navigation-overlay__meta__menu__title__label {
	color: var(--color-purple);
}

.navigation-overlay__meta__menu__submenu {
	background-color: rgb(27 91 154 / 0.075);
	border-bottom: 1px solid rgb(27 91 154 / 0.25);
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0.5rem 3.75%;
}

@media (width >= 48rem) {
	.navigation-overlay__meta__menu__submenu {
		padding: 0.5rem 3%;
	}
}

.navigation-overlay__meta__menu__submenu__item a {
	color: var(--color-blue);
	display: block;
	font-size: 0.875rem;
	padding: 0.5rem 0 0.5rem 1.125rem;
	position: relative;
	text-decoration: none;
}

.navigation-overlay__meta__menu__submenu__item--active a::before {
	background-color: var(--color-blue);
	border-radius: 6px;
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 6px;
	height: 6px;
}
/* #endregion Meta Navigation */

/* #region Social */
.navigation-overlay__social {
	padding: 2rem 3.75%;
}

@media (width >= 48rem) {
	.navigation-overlay__social {
		padding: 2rem 3%;
	}
}

/* #endregion Social */
