.l-404 {
	background-color: var(--color-blue-light);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	min-height: 100vh;
}

.l-404__title {
	margin: 0 0 2rem;
}

@media (width >= 64rem) {
	.l-404__title {
		margin: 0 0 3rem;
	}
}

.l-404__logo {
	display: block;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
	width: 5rem;
	height: 1.5rem;
}

@media (width >= 64rem) {
	.l-404__logo {
		margin-bottom: 3rem;
		width: 6rem;
		height: 1.8rem;
	}
}

@media (width >= 86.25rem) {
	.l-404__logo {
		width: 7.5rem;
		height: 2.25rem;
	}
}

.l-404__logo a {
	display: block;
	text-indent: -9999em;
	width: 100%;
	height: 100%;
}

.l-404__logo a svg {
	display: block;
	width: 100%;
	height: 100%;
}

.l-404__logo a svg .text {
	fill: var(--color-blue);
}

.l-404__logo a svg .line {
	fill: var(--color-purple);
}
