.tag-filter {
	background-color: var(--color-blue-light);
	border-top: 1px solid var(--color-blue-lightened);
	text-align: center;
}

.tag-filter__form__submit {
	display: none;
}

.tag-filter__list {
	list-style: none;
	margin: 0;
	padding: 1.5rem 3.75% 1rem;
	text-align: left;
}

@media (width >= 48rem) {
	.tag-filter__list {
		display: flex;
		flex-wrap: wrap;
		padding-right: 3%;
		padding-left: 3%;
		justify-content: center;
	}
}

@media (width >= 86.25rem) {
	.tag-filter__list {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

.tag-filter__list__item {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0 0 0.25rem 0;
}

@media (width >= 48rem) {
	.tag-filter__list__item {
		flex-basis: auto;
		margin-bottom: 1rem;
	}
}

.tag-filter__list__item > input {
	display: none;
}

.tag-filter__form__label {
	box-sizing: border-box;
	color: var(--color-blue);
	cursor: pointer;
	font-size: 0.75rem;
	font-weight: 600;
	line-height: 1.5rem;
	padding: 0 2rem 0 1.5rem;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	transition: color 96ms linear;
	user-select: none;
	height: 1.5rem;
}

.tag-filter__list__item:last-child .tag-filter__form__label {
	padding-right: 0;
}

.tag-filter__form__label::before {
	border: 1px solid var(--color-blue);
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 10px;
	height: 10px;
}

.tag-filter__form__label.is-active::before {
	background-color: var(--color-blue);
}

.tag-filter__form__label:hover {
	color: var(--color-purple);
}

input + .tag-filter__form__label::before {
	border-radius: 0;
}

input:checked + .tag-filter__form__label::before {
	background-color: var(--color-blue);
}

.tag-filter__link {
	color: var(--color-blue);
	margin-right: 10px;
	margin-left: 10px;
	text-decoration: none;
}

.tag-filter__link:focus,
.tag-filter__link:hover,
.tag-filter__link.is-active {
	color: var(--color-purple);
	text-decoration: underline;
}

.tag-filter__link.is-active {
	font-weight: 700;
}

.tag-filter__toggle-link {
	box-sizing: border-box;
	color: var(--color-blue);
	cursor: pointer;
	display: inline-block;
	font-size: 0.75rem;
	font-weight: 600;
	line-height: 1.5rem;
	padding: 0 2rem 0 1.5rem;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	transition: color 96ms linear;
	user-select: none;
	height: 1.5rem;
}

.tag-filter__toggle-link:any-link {
	color: var(--color-blue);
}

.tag-filter__list__item:last-child .tag-filter__toggle-link {
	padding-right: 0;
}

.tag-filter__toggle-link::before {
	border: 1px solid var(--color-blue);
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 10px;
	height: 10px;
}

.tag-filter__toggle-link[data-is-active]::before {
	background-color: var(--color-blue);
}

.tag-filter__toggle-link:hover {
	color: var(--color-purple);
}
