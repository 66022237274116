.rating-forms {
	display: flex;
	flex-wrap: wrap;
	margin-top: 0.625rem;
}

.rating-forms__button {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	color: var(--color-purple);
	line-height: 2.125rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0;
	text-decoration: underline;
}

@media (width >= 48rem) {
	.rating-forms__button {
		margin-bottom: 0;
	}
}

.t-jac .rating-forms__button {
	color: var(--color-orange);
}

.rating-forms__button:hover {
	color: var(--color-black);
	cursor: pointer;
}

.rating-forms__message:not(:empty) {
	border: 1px solid var(--color-grey-light);
	box-sizing: border-box;
	padding: 0.25rem 0.5rem;
	width: max-content;
}

@media (width < 48rem) {
	.rating-forms__message {
		margin-bottom: 0.5rem;
	}
}

.rating-forms__message p {
	font-size: inherit;
}
