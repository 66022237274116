.related-posts {
	border-top: 1px solid var(--color-grey-light);
}

.related-posts__title {
	margin-bottom: 2rem;
	text-align: center;
}

@media (width >= 48rem) {
	.related-posts__title {
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.related-posts__title {
		margin-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.related-posts__title {
		margin-bottom: 5.625rem;
	}
}

@media (width >= 35.5rem) {
	.related-posts__grid {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}
}

@media (width >= 64rem) {
	.related-posts__grid {
		justify-content: flex-start;
	}
}

.related-posts__grid__item {
	display: block;
	text-decoration: none;
}

@media (width >= 35.5rem) {
	.related-posts__grid__item {
		flex: 0 0 calc(50% - 1rem);
	}
}

@media (width >= 64rem) {
	.related-posts__grid__item {
		flex: 0 0 calc(25% - 1rem);
	}
}

@media (width >= 86.25rem) {
	.related-posts__grid__item {
		flex: 0 0 calc(25% - 1.5rem);
	}
}

@media (width < 35.5rem) {
	.related-posts__grid__item:not(:last-child) {
		border-bottom: 1px solid var(--color-grey-light);
		margin-bottom: 2rem;
		padding-bottom: 2rem;
	}
}

@media (width >= 64rem) {
	.related-posts__grid__item:not(:last-child) {
		margin-right: 1.3333rem;
	}
}

@media (width >= 86.25rem) {
	.related-posts__grid__item:not(:last-child) {
		margin-right: 2rem;
	}
}

@media (35.5rem <= width < 64rem) {
	.related-posts__grid__item:first-child,
	.related-posts__grid__item:nth-child(2) {
		border-bottom: 1px solid var(--color-grey-light);
		margin-bottom: 2rem;
		padding-bottom: 2rem;
	}
}
