.menu-toggle {
	appearance: none;
	background-color: transparent;
	border: 0;
	box-shadow: none;
	box-sizing: content-box;
	cursor: pointer;
	display: block;
	padding-inline: 0.625rem;
	padding-block: 0.9375rem;
	position: relative;
	width: 1.5rem;
	height: 0.875rem;
}

.menu-toggle:active {
	box-shadow: none;
	outline: 0;
}

.menu-toggle > span {
	background-color: var(--color-blue);
	display: block;
	position: absolute;
	left: 0.625rem;
	width: 1.5rem;
	height: 0.1875rem;
}

.menu-toggle > span:nth-child(1) {
	top: 0.9375rem;
}

.menu-toggle > span:nth-child(2) {
	top: 1.3125rem;
}

.menu-toggle > span:nth-child(3) {
	top: 1.6875rem;
}
