.wrapper {
	margin-right: auto;
	margin-left: auto;
	position: relative;
	width: 92.5%;
	max-width: 1582px;
}

@media (width >= 48rem) {
	.wrapper {
		width: 94%;
	}
}

.wrapper.wrapper--medium {
	max-width: 1400px;
}

@media (width >= 48rem) {
	.wrapper.wrapper--medium {
		width: 83.3333%;
	}
}

.wrapper.wrapper--small {
	max-width: 980px;
}

@media (width >= 48rem) {
	.wrapper.wrapper--small {
		width: 66.6666%;
	}
}

.wrapper.wrapper--extra-small {
	max-width: 672px;
}

@media (width >= 48rem) {
	.wrapper.wrapper--extra-small {
		width: 66.6666%;
	}
}

@media (width >= 64rem) {
	.wrapper.wrapper--extra-small {
		width: 50%;
	}
}

@media (width >= 86.25rem) {
	.wrapper.wrapper--extra-small {
		width: 40%;
	}
}

.wrapper.wrapper--standalone {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

@media (width >= 48rem) {
	.wrapper.wrapper--standalone {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.wrapper.wrapper--standalone {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.wrapper.wrapper--standalone {
		margin-top: 5.625rem;
		margin-bottom: 5.625rem;
	}
}

.colored-section + .flexible-content > .wrapper:first-child,
.wrapper--standalone + .flexible-content > .wrapper:first-child,
.flexible-content + .wrapper {
	margin-top: 0;
}

.wrapper.wrapper--centered {
	text-align: center;
}
