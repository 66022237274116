.styleguide__navigation {
	list-style: none;
	margin: 2rem 0;
	padding: 0;
}

.styleguide__navigation li::before {
	content: "☞";
	font-size: 1.5rem;
	margin-right: 0.5em;
	vertical-align: middle;
}

.styleguide__navigation a {
	color: var(--color-blue);
	font-size: 1rem;
}

.styleguide__navigation a:hover,
.styleguide__navigation a:focus {
	color: var(--color-purple);
}

.styleguide__item {
	padding: 2rem 0;
}

@media (width >= 48rem) {
	.styleguide__item {
		padding: 3rem 0;
	}
}

.styleguide__item:nth-of-type(odd) {
	background-color: #fafafa;
}

.styleguide__title {
	color: var(--color-blue);
	display: grid;
	font-family: var(--font-serif);
	font-size: 1.5rem;
	font-weight: 500;
	grid-column-gap: 1rem;
	grid-template-columns: 1fr max-content 1fr;
	margin-bottom: 2rem;
	padding-right: 1rem;
	padding-left: 1rem;
	align-items: center;
	text-align: center;
}

.styleguide__title::before,
.styleguide__title::after {
	background-color: currentcolor;
	content: "";
	height: 1px;
}
