@media (width < 64rem) {
	.meta-navigation {
		width: 100%;
	}
}

@media (width >= 64rem) {
	.meta-navigation {
		box-sizing: border-box;
		color: var(--color-grey);
		display: flex;
		font-size: 0.8125rem;
		list-style: none;
		margin: 0;
		padding: 0 0.375rem;
	}
}

@media (width >= 64rem) {
	.meta-navigation + .meta-navigation {
		border-left: 1px solid var(--color-grey-light);
	}
}

.meta-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (width >= 64rem) {
	.meta-navigation__list {
		display: flex;
	}
}

@media (width >= 64rem) {
	.meta-navigation__item {
		display: inline-block;
		font-weight: 500;
	}
}

.meta-navigation__item:not(:last-child) {
	position: relative;
}

@media (width >= 64rem) {
	.meta-navigation__item:not(:last-child)::after {
		background-color: currentcolor;
		content: "";
		display: block;
		font-family: Arial, sans-serif;
		font-weight: 100;
		opacity: 0.625;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 1px;
		height: 1rem;
	}
}

.footer .meta-navigation__item {
	margin: 0.5rem;
}

.footer .meta-navigation__item:first-child {
	margin-top: 0;
}

.footer .meta-navigation__item:last-child {
	margin-bottom: 0;
}

@media (width >= 64rem) {
	.footer .meta-navigation__item {
		box-sizing: border-box;
		margin: 0;
		padding: 0 0.5rem;
	}
}

@media (width >= 64rem) {
	.footer .meta-navigation__item:last-child {
		padding-right: 0;
	}
}

.footer .meta-navigation__item a {
	color: var(--color-grey);
}

.meta-navigation__title {
	color: currentcolor;
	display: block;
	text-decoration: none;
}

@media (width < 64rem) {
	.meta-navigation__title {
		border-bottom: 1px solid rgb(27 91 154 / 0.25);
		overflow: hidden;
		position: relative;
	}
}

@media (width >= 64rem) {
	.meta-navigation__title {
		padding: 1rem 0.625rem;
	}
}

@media (width >= 64rem) {
	.t-jac .meta-navigation__title {
		color: var(--color-black);
	}
}

@media (width >= 64rem) {
	.meta-navigation__title:hover {
		color: var(--color-blue);
		text-decoration: underline;
	}
}

@media (width >= 64rem) {
	.t-jac .meta-navigation__title:hover {
		color: var(--color-orange);
	}
}

@media (width >= 64rem) {
	.meta-navigation__item--active .meta-navigation__title {
		color: var(--color-blue);
	}
}

@media (width >= 64rem) {
	.t-jac .meta-navigation__item--active .meta-navigation__title {
		color: var(--color-orange);
	}
}

.meta-navigation__item--highlighted:not(.meta-navigation__item--active) .meta-navigation__title:not(:focus):not(:hover) {
	color: var(--color-purple);
}

.meta-navigation__title__label {
	color: currentcolor;
	position: relative;
	z-index: 1;
}

@media (width < 64rem) {
	.meta-navigation__item--active .meta-navigation__title__label,
	.meta-navigation__toggle[aria-expanded="true"] + .meta-navigation__title__label {
		background-color: rgb(27 91 154 / 0.075);
	}
}

@media (width < 64rem) {
	.meta-navigation__title__label {
		box-sizing: border-box;
		color: var(--color-blue);
		display: block;
		padding: 0.75rem 0 0.75rem 3.75%;
	}
}

@media (48rem <= width < 64rem) {
	.meta-navigation__title__label {
		padding: 0.75rem 0 0.75rem 3%;
	}
}

@media (width < 64rem) {
	.meta-navigation__item--highlighted:not(.meta-navigation__item--active) .meta-navigation__title__label {
		color: var(--color-purple);
	}
}
