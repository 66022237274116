.packed-grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
}

@supports (display: grid) {
	.packed-grid {
		display: grid;
		grid-gap: 1rem;
		grid-auto-flow: row dense;
		justify-content: normal;
	}

	@media (width >= 35.5rem) {
		.packed-grid {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (width >= 48rem) {
		.packed-grid {
			grid-gap: 2rem;
		}
	}

	@media (width >= 64rem) {
		.packed-grid {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (width >= 80rem) {
		.packed-grid {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.packed-grid__item {
	border-bottom: 1px solid var(--color-grey-light);
	flex-basis: 100%;
	margin-bottom: 1rem;
	padding-bottom: 2rem;
	text-decoration: none;
}

@media (width >= 35.5rem) {
	.packed-grid__item {
		flex-basis: calc(50% - 0.5rem);
		margin-right: 1rem;
	}
}

@media (width >= 48rem) {
	.packed-grid__item {
		flex-basis: calc(50% - 1rem);
		margin-right: 2rem;
		margin-bottom: 2rem;
	}
}

@media (width >= 64rem) {
	.packed-grid__item {
		flex-basis: calc(33.3333% - 1.3333rem);
		margin-right: 1.9999rem;
	}
}

@media (35.5rem <= width < 64rem) {
	.packed-grid__item:nth-child(2n + 2) {
		margin-right: 0;
	}
}

@media (width >= 64rem) {
	.packed-grid__item:nth-child(3n + 3) {
		margin-right: 0;
	}
}

@supports (display: grid) {
	.packed-grid__item {
		flex-basis: auto;
		margin-right: 0;
		margin-bottom: 0;
	}
}

.packed-grid__item--empty-notice {
	border-bottom: 0;
	display: none;
	flex-basis: 100%;
}

@supports (display: grid) {
	.packed-grid__item--empty-notice {
		grid-column: 1 / span 1;
	}

	@media (width >= 35.5rem) {
		.packed-grid__item--empty-notice {
			grid-column: 1 / span 2;
		}
	}

	@media (width >= 64rem) {
		.packed-grid__item--empty-notice {
			grid-column: 1 / span 3;
		}
	}
}

.packed-grid__item--empty-notice::after {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-blue);
	font-family: var(--font-serif);
	font-size: 1.5rem;
	font-weight: 500;
	margin-top: 2rem;
	margin-bottom: 2rem;
	text-align: center;
	width: 100%;
}

@media (width >= 48rem) {
	.packed-grid__item--empty-notice::after {
		font-size: 1.875rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.packed-grid__item--empty-notice::after {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.packed-grid__item--empty-notice::after {
		font-size: 2.25rem;
		margin-top: 5.625rem;
		margin-bottom: 5.625rem;
	}
}

.packed-grid__item[style] ~ .packed-grid__item--empty-notice {
	display: flex;
}

.packed-grid__item[style] ~ .packed-grid__item--empty-notice::after {
	content: "Geen posts gevonden";
}

.packed-grid__item:not([style]) ~ .packed-grid__item--empty-notice,
.has-pages .packed-grid__item--empty-notice {
	display: none;
}
