.hero {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

@media (width >= 48rem) {
	.hero {
		margin-top: 2rem;
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.hero {
		margin-top: 0;
		margin-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.hero {
		margin-bottom: 5.625rem;
	}
}

.colored-section .hero:only-child {
	margin-bottom: 0;
}

.hero.hero--image {
	overflow: hidden;
	position: relative;
}

@media (width < 48rem) {
	.hero.hero--image {
		margin-top: 1rem;
	}
}

@media (width >= 48rem) {
	.hero.hero--intro {
		display: flex;
		justify-content: flex-end;
	}
}

.hero__title {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	color: var(--color-blue);
	font-family: var(--font-serif);
	font-size: 2rem;
}

@media (width >= 48rem) {
	.hero__title {
		margin-bottom: 2.25rem;
	}
}

@media (width >= 64rem) {
	.hero__title {
		font-size: 2.5rem;
		margin-bottom: 2.5rem;
	}
}

@media (width >= 86.25rem) {
	.hero__title {
		font-size: 3.125rem;
		margin-bottom: 3.25rem;
	}
}

.colored-section .hero__title:last-child {
	margin-bottom: 0;
}

.hero--banner .hero__title,
.hero--image .hero__title {
	font-size: 1.875rem;
	line-height: 1.1;
}

@media (width >= 48rem) {
	.hero--banner .hero__title,
	.hero--image .hero__title {
		font-size: 2.25rem;
	}
}

@media (width >= 86.25rem) {
	.hero--banner .hero__title,
	.hero--image .hero__title {
		font-size: 3rem;
	}
}

.hero--image .hero__title {
	margin-top: 0.375em;
	margin-bottom: 0;
}

.hero--banner .hero__title {
	color: var(--color-purple);
	margin-top: 0;
}

.t-jac .hero--banner .hero__title {
	color: var(--color-orange);
}

.hero:not(.hero--banner):not(.hero--image) .hero__title {
	text-align: center;
}

.hero__subtitle {
	color: var(--color-grey);
	font-size: 1rem;
	font-weight: 400;
}

@media (width >= 48rem) {
	.hero__subtitle {
		font-size: 1.25rem;
	}
}

@media (width >= 86.25rem) {
	.hero__subtitle {
		font-size: 1.5rem;
	}
}

.hero--banner .hero__subtitle {
	color: var(--color-purple);
	margin: 0;
}

.t-jac .hero--banner .hero__subtitle {
	color: var(--color-orange);
}

.hero__link {
	color: var(--color-purple);
	display: inline-block;
	margin-top: 1rem;
	transition: color 96ms linear;
}

@media (width >= 64rem) {
	.hero__link {
		font-size: 1.25rem;
	}
}

@media (width >= 86.25rem) {
	.hero__link {
		margin-top: 2rem;
	}
}

.hero__link:hover {
	color: var(--color-blue);
}

.t-jac .hero__link:hover {
	color: var(--color-orange);
}

@media (width < 48rem) {
	.hero__content {
		margin-top: 1rem;
	}
}

@media (width >= 48rem) {
	.hero__content {
		padding-right: 50%;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		z-index: 2;
	}
}

@media (width >= 64rem) {
	.hero__content {
		padding-right: 0;
		width: 33.3333%;
	}
}

.hero__image {
	position: relative;
	width: auto;
	z-index: 1;
}

.hero:not(.hero--intro) .hero__image {
	max-width: 980px;
}

@media (width >= 35.5rem) {
	.hero:not(.hero--intro) .hero__image {
		display: block;
		margin-left: auto;
		width: 66.6666vw;
	}
}

@media (width < 48rem) {
	.hero.hero--intro .hero__image {
		display: block;
		margin-top: 2rem;
	}
}

.hero__intro-content {
	font-weight: 600;
}

.hero--intro .hero__intro-content {
	margin-top: 2rem;
}

@media (width >= 64rem) {
	.hero--intro .hero__intro-content {
		margin-top: 3rem;
	}
}

@media (width >= 86.25rem) {
	.hero--intro .hero__intro-content {
		margin-top: 4.25rem;
	}
}
