@media (width >= 48rem) {
	.block-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 0 3rem;
		justify-content: space-around;
		align-items: flex-start;
	}
}

.block-grid__item {
	background-color: var(--color-grey);
	box-sizing: border-box;
	margin-top: 1rem;
	text-align: center;
}

@media (width >= 48rem) {
	.block-grid__item {
		flex: 0 0 calc(50% - 1rem);
	}
}

@media (width >= 64rem) {
	.block-grid__item {
		flex: 0 0 calc(33.3333% - 1rem);
	}
}

@media (width >= 86.25rem) {
	.block-grid__item {
		flex: 0 0 calc(20% - 1rem);
	}
}

.block-grid__item a {
	display: block;
	line-height: 120px;
	width: 100%;
	height: 120px;
}

@media (width >= 64rem) {
	.block-grid--large .block-grid__item {
		flex: 0 0 calc(33.3333% - 1rem);
	}
}
