body {
	font-family: var(--font-sans);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--color-blue);
	font-family: var(--font-serif);
	font-weight: 500;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

.colored-section--blue h1,
.colored-section--blue h2,
.colored-section--blue h3,
.colored-section--blue h4,
.colored-section--blue h5,
.colored-section--blue h6 {
	color: var(--color-white);
}

h1 {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.875rem;
}

@media (width >= 48rem) {
	h1 {
		font-size: 2rem;
	}
}

@media (width >= 86.25rem) {
	h1 {
		font-size: 3rem;
	}
}

h2 {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.5rem;
}

@media (width >= 48rem) {
	h2 {
		font-size: 1.875rem;
	}
}

@media (width >= 86.25rem) {
	h2 {
		font-size: 2.25rem;
	}
}

h3 {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.25rem;
}

@media (width >= 48rem) {
	h3 {
		font-size: 1.5rem;
	}
}

h4 {
	font-size: 1.125rem;
}

@media (width >= 48rem) {
	h4 {
		font-size: 1.25rem;
	}
}

h5 {
	color: var(--color-purple);
	font-size: 1.125rem;
}

@media (width >= 48rem) {
	h5 {
		font-size: 1.25rem;
	}
}

.t-jac h5 {
	color: var(--color-orange);
}

h6 {
	font-size: 1rem;
}

strong {
	font-weight: 600;
}

a {
	color: var(--color-purple);
	transition: color 96ms linear;
}

p > a:not([class]) {
	font-weight: 600;
}

.t-jac a {
	color: var(--color-orange);
}

p > a:not([class]):hover {
	color: var(--color-blue);
}

p {
	font-size: 1rem;
	line-height: 1.75;
	margin-top: 0;
	margin-bottom: 2em;
}

p:last-of-type {
	margin-bottom: 0;
}

ul:not([class]) {
	margin-bottom: 2em;
}

.gfield > ul {
	list-style-type: disc;
}

.gfield > ul ul {
	list-style-type: circle;
}

li,
dd,
dt {
	line-height: 1.75;
}

dt {
	color: var(--color-blue);
	font-family: var(--font-serif);
	font-size: 1.5rem;
	font-weight: 500;
}

blockquote {
	color: var(--color-purple);
	font-family: var(--font-serif);
	font-size: 1.5rem;
	font-weight: 500;
	margin: 5rem 0;
	text-align: center;
}

@media (width >= 48rem) {
	blockquote {
		font-size: 1.875rem;
	}
}

@media (width >= 86.25rem) {
	blockquote {
		font-size: 2.25rem;
	}
}

.t-jac blockquote {
	color: var(--color-orange);
}

blockquote p {
	font-size: inherit;
	line-height: 1.3;
}

cite {
	/* To prevent people abusing the formatter outside of blockquotes */
	background-color: red;
	color: red;
}

blockquote cite {
	background-color: transparent;
	color: inherit;
	display: block;
	font-size: 1.25rem;
	margin-top: 1rem;
	text-align: center;
}

@media (width >= 86.25rem) {
	blockquote cite {
		font-size: 1.5rem;
		margin-top: 2rem;
	}
}

button {
	font-family: var(--font-sans);
}
