.image-group {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.image-group__item {
	flex-basis: calc(50% - 0.5rem);
}

@media (width >= 48rem) {
	.image-group__item {
		flex-basis: calc(50% - 1rem);
	}
}

.image-group__image {
	display: block;
	width: 100%;
	height: auto;
}

.image-group__item__caption {
	display: inline-block;
	font-size: 0.875rem;
	line-height: 1.75;
	padding-top: 0.25rem;
	text-align: right;
	width: 100%;
}
