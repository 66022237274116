@media (width >= 48rem) {
	.locations {
		display: flex;
	}
}

.locator .locations {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (width < 48rem) {
	.locator .locations {
		display: block;
	}
}

@media (width >= 48rem) {
	.locator .locations {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.locator .locations {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.locator .locations {
		padding-top: 5.625rem;
		padding-bottom: 5.625rem;
	}
}

.locations__meta {
	margin-bottom: 2rem;
}

@media (width >= 48rem) {
	.locations__meta {
		box-sizing: border-box;
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		flex-direction: column;
		margin-bottom: 0;
		padding-right: 2rem;
	}
}

@media (width >= 64rem) {
	.locations__meta {
		padding-right: 3rem;
	}
}

.locations__header {
	margin-bottom: 1.5rem;
	max-width: 320px;
}

.locations__header p:not(:last-child) {
	margin-bottom: 1rem;
}

.locations__title {
	margin-bottom: 1rem;
}

.locations--v2 .locations__title > strong {
	color: var(--color-purple);
	font-weight: 500;
}

.locations__select {
	margin-top: -1rem;
	margin-bottom: 1rem;
}

@media (width < 48rem) {
	.locations__map-container {
		display: none;
	}
}

.locations:not(.locations--v2):not(.is-resolved) .locations__map-container,
.locations.is-empty .locations__map-container {
	display: none;
}

.locations__map {
	background-color: var(--color-blue-lightened);
	margin-top: auto;
	padding-bottom: 133.33%;
	position: relative;
}

.locations__map .map {
	position: absolute;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.locations__show-location {
	appearance: none;
	background-color: transparent;
	border: none;
	color: var(--color-purple);
	font-family: var(--font-sans);
	margin-top: 0.5rem;
	margin-left: -0.5rem;
	padding: 0.5rem;
	text-decoration: underline;
}

.t-jac .locations__show-location {
	color: var(--color-orange);
}

.locations__show-location:hover {
	cursor: pointer;
}

@media (width >= 48rem) {
	.locations__list {
		flex-basis: 50%;
		flex-grow: 1;
		flex-shrink: 0;
	}
}

@media (width >= 86.25rem) {
	.locations__list {
		flex-basis: 60%;
	}
}

.locations:not(.locations--v2):not(.is-resolved) .locations__list {
	display: none;
}

.locations__empty {
	background-color: var(--color-blue);
	color: var(--color-white);
	padding: 0.625rem 1rem;
	text-align: center;
}
