.pagination {
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.pagination__list {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

.pagination__item {
	display: inline-block;
	margin: 0 0.5rem;
}
