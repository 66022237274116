.footer__doormat {
	/* background-color: var(--color-blue-light); */
	background-color: #e3ecf5;
}

.footer__footer {
	background-color: var(--color-white);
	box-sizing: border-box;
	color: var(--color-grey);
	font-size: 0.8125rem;
	padding: 1.5rem 0;
}

@media (width < 64rem) {
	.footer__footer {
		text-align: center;
	}
}

@media (width >= 64rem) {
	.footer__footer {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}

.footer__social {
	margin: 2rem 0;
	padding: 0;
}

@media (width >= 64rem) {
	.footer__social {
		margin: 0 0 0 2rem;
	}
}

.footer__social li {
	display: inline-block;
	margin: 0 0.5rem;
}

@media (width >= 64rem) {
	.footer__social li {
		margin: 0 1rem 0 0;
	}
}

@media (width >= 64rem) {
	.footer__navigation {
		margin-right: 0;
		margin-left: auto;
	}
}

@media (width < 64rem) {
	.footer__doormat > .wrapper {
		width: 100%;
	}
}
