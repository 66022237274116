.link-grid {
	background-color: var(--color-blue-light);
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 48rem) {
	.link-grid {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.link-grid {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.link-grid {
		padding-top: 5.625rem;
		padding-bottom: 5.625rem;
	}
}

.link-grid__title {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-blue);
	font-family: var(--font-serif);
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.3333;
	margin-top: 0;
	margin-bottom: 2rem;
	text-align: center;
}

@media (width >= 48rem) {
	.link-grid__title {
		font-size: 1.5rem;
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.link-grid__title {
		margin-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.link-grid__title {
		font-size: 1.875rem;
		margin-bottom: 5.625rem;
	}
}

.link-grid__list {
	display: flex;
	flex-flow: row wrap;
	list-style: none;
	margin: 0 auto;
	padding: 0;
	justify-content: flex-start;
}

@supports (display: grid) {
	.link-grid__list {
		display: grid;
		grid-gap: 0.5em;
		justify-content: normal;
	}

	@media (width >= 35.5rem) {
		.link-grid__list {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (width >= 64rem) {
		.link-grid__list {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.link-grid__list-item {
	background-color: var(--color-white);
	box-sizing: border-box;
	flex-basis: 100%;
	margin-bottom: 0.5rem;
	text-decoration: none;
}

@media (width >= 35.5rem) {
	.link-grid__list-item {
		flex-basis: calc(50% - 2.75rem);
		margin-right: 0.5rem;
	}
}

@media (width >= 64rem) {
	.link-grid__list-item {
		flex-basis: calc(33.3333% - 4.375rem);
	}
}

@media (width >= 86.25rem) {
	.link-grid__list-item {
		flex-basis: calc(33.3333% - 5.375rem);
	}
}

@media (35.5rem <= width < 64rem) {
	.link-grid__list-item:nth-child(2n + 2) {
		margin-right: 0;
	}
}

@media (width >= 64rem) {
	.link-grid__list-item:nth-child(3n + 3) {
		margin-right: 0;
	}
}

[data-text-align="center"] .link-grid__list-item {
	text-align: center;
}

.link-grid__list-item__link {
	display: block;
	padding: 1.25em;
	text-decoration: none;
}

@media (width >= 64rem) {
	.link-grid__list-item__link {
		padding: 2rem;
	}
}

@media (width >= 86.25rem) {
	.link-grid__list-item__link {
		padding: 2.5rem;
	}
}

@supports (display: grid) {
	.link-grid__list-item {
		flex-basis: auto;
		margin-right: 0;
		margin-bottom: 0;
	}
}

.link-grid__list-item__title {
	color: var(--color-purple);
	font-family: var(--font-serif);
	font-size: 1rem;
	margin-top: 1rem;
	text-transform: uppercase;
	transition: color 96ms linear;
}

@media (width >= 86.25rem) {
	.link-grid__list-item__title {
		font-size: 1.125rem;
	}
}

.t-jac .link-grid__list-item__title {
	color: var(--color-orange);
}

.link-grid__list-item:hover .link-grid__list-item__title {
	color: var(--color-blue);
}
