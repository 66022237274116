@media (width >= 35.5rem) {
	.packed-grid__item .post-card,
	.related-posts__grid__item .post-card {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.packed-grid__item .post-card .post-card__poster,
	.related-posts__grid__item .post-card__poster {
		margin-top: auto;
	}
}

.post-card__meta {
	color: var(--color-grey);
	font-size: 0.875rem;
}

.post-card__meta a {
	color: currentcolor;
	text-decoration: none;
}

.post-card--job .post-card__meta {
	color: var(--color-black);
}

.post-card__meta strong {
	font-weight: 400;
}

.post-card__title {
	color: var(--color-black);
	font-size: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

:any-link:hover .post-card__title {
	color: var(--color-purple);
}

.t-jac :any-link:hover .post-card__title {
	color: var(--color-orange);
}

.post-card__title a {
	color: currentcolor;
	text-decoration: none;
}

@media (64rem <= width < 86.25rem) {
	.related-posts .post-card__title {
		font-size: 1.25rem;
	}
}

.post-card__poster {
	display: block;
	width: 100%;
}

.post-card__poster:not(:last-child) {
	margin-bottom: 1rem;
}

.post-card__poster img {
	vertical-align: middle;
	width: 100%;
	height: auto;
}

.post-card__intro {
	font-size: 0.875rem;
}

.post-card__intro p {
	font-size: inherit;
}

.post-card__intro p:not(:last-child) {
	margin-bottom: 1em;
}
