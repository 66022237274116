.round-thumbnail {
	color: var(--color-black);
	text-align: center;
	text-decoration: none;
}

.round-thumbnail__asset,
.round-thumbnail__placeholder {
	border-radius: 50%;
	display: block;
	margin-bottom: 1rem;
	overflow: hidden;
	width: 100%;
	height: auto;
}

@media (width >= 86.25rem) {
	.round-thumbnail__asset,
	.round-thumbnail__placeholder {
		margin-bottom: 2rem;
	}
}

.round-thumbnail__title {
	color: var(--color-purple);
	margin: 0;
}

.t-jac .round-thumbnail__title {
	color: var(--color-orange);
}

.round-thumbnail--link .round-thumbnail__title {
	text-decoration: underline;
	transition: color 96ms linear;
}

.round-thumbnail--link:focus .round-thumbnail__title,
.round-thumbnail--link:hover .round-thumbnail__title {
	color: var(--color-blue);
}

.round-thumbnail__asset-wrapper {
	border-radius: 50%;
	display: block;
	margin-bottom: 1rem;
	overflow: hidden;
	position: relative;
	text-decoration: none;
}

@media (width < 48rem) {
	.round-thumbnail__asset-wrapper {
		margin-right: auto;
		margin-left: auto;
		max-width: 60%;
	}
}

@media (width >= 86.25rem) {
	.round-thumbnail__asset-wrapper {
		margin-bottom: 2rem;
	}
}

.round-thumbnail__placeholder {
	filter: brightness(0.8); /* there is a base64 encoded png which I don't know how to edit, but this also gives it more contrast */
}

.round-thumbnail__asset-wrapper .round-thumbnail__asset {
	margin-bottom: 0;
	position: relative;
	z-index: 1;
}
