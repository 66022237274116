.contact-shortcuts {
	margin: 1.875rem 0 1.25rem 0;
}

@media (width >= 48rem) {
	.contact-shortcuts {
		display: flex;
		margin: 1.875rem 0 1.875rem 0;
		justify-content: space-between;
	}
}

@media (width >= 90rem) {
	.contact-shortcuts {
		margin: 0 auto;
		padding: 4.375rem 0 8.125rem 0;
		max-width: 62.5rem;
	}
}

/* #region Header */
.contact-shortcuts__header {
	text-align: center;
}

@media (width >= 48rem) {
	.contact-shortcuts__header {
		flex-basis: calc(50% - 10px);
	}

	.contact-shortcuts__header .hero__title {
		margin-bottom: 1.875rem;
	}
}

@media (width >= 90rem) {
	.contact-shortcuts__header {
		flex-basis: calc(50% - 5px);
	}

	.contact-shortcuts__header .hero__title {
		margin-bottom: 1.625rem;
	}
}
/* #endregion Header */

/* #region Items */
.contact-shortcuts__methods {
	flex-basis: calc(50% - 10px);
}

@media (width >= 90rem) {
	.contact-shortcuts__methods {
		flex-basis: calc(50% - 5px);
		max-width: 25.625rem;
	}
}

.contact-shortcuts__methods__link-list {
	display: grid;
	grid-row-gap: 1.25rem;
	grid-column-gap: 20px;
	grid-template-columns: repeat(2, 1fr);
	list-style: none;
	margin-top: 1.75rem;
	margin-bottom: 0;
	padding: 0;
}

@media (width >= 48rem) {
	.contact-shortcuts__methods__link-list {
		margin-top: 0;
	}
}
/* #endregion Items */

/* #region Item */
.contact-shortcuts__method {
	color: var(--color-blue);
	text-decoration: none;
}

.contact-shortcuts__method__title {
	border-bottom: 2px solid var(--color-blue);
	font-family: var(--font-serif);
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.35;
	margin-bottom: 8px;
	width: max-content;
}

@media (width >= 90rem) {
	.contact-shortcuts__method__title {
		font-size: 1.5rem;
		line-height: 1.17;
	}
}

.contact-shortcuts__method__icon {
	fill: var(--color-blue);
}

.contact-shortcuts__method__icon .icon {
	margin-left: 4px;
	width: 1rem;
	height: 1rem;
}

.contact-shortcuts__method__icon .icon-pin {
	margin-left: 0;
}

.contact-shortcuts__method__icon .icon-phone {
	margin-left: 2px;
	width: 0.9375rem;
	height: 1rem;
}

@media (width >= 90rem) {
	.contact-shortcuts__method__icon .icon-mail {
		width: 1.25rem;
		height: 1.25rem;
	}

	.contact-shortcuts__method__icon .icon-chat {
		margin-left: 2px;
		width: 1.0625rem;
		height: 1.0625rem;
	}

	.contact-shortcuts__method__icon .icon-pin {
		margin-left: -2px;
		width: 1.0625rem;
		height: 1.0625rem;
	}
}

.contact-shortcuts__method__description {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	margin-top: 0.375rem;
}

/* Hover */
.contact-shortcuts__method:hover {
	color: var(--color-purple);
	cursor: pointer;
}

.contact-shortcuts__method:hover .contact-shortcuts__method__title {
	border-bottom: 2px solid var(--color-purple);
}

.contact-shortcuts__method:hover .contact-shortcuts__method__icon {
	fill: var(--color-purple);
}
/* #endregion Item */

/* #region JAC */
.t-jac .contact-shortcuts__method {
	color: var(--color-black);
}

.t-jac .contact-shortcuts__method__title {
	border-bottom: 2px solid var(--color-black);
}

.t-jac .contact-shortcuts__method__icon {
	fill: var(--color-black);
}

/* Hover */
.t-jac .contact-shortcuts__method:hover {
	color: var(--color-orange);
}

.t-jac .contact-shortcuts__method:hover .contact-shortcuts__method__title {
	border-bottom: 2px solid var(--color-orange);
	transition: none;
}

.t-jac .contact-shortcuts__method:hover .contact-shortcuts__method__icon {
	fill: var(--color-orange);
}
/* #endregion JAC */
