.primary-navigation {
	color: var(--color-blue);
	font-size: 1.125rem;
	font-weight: 400;
	text-align: center;
}

@media (width >= 86.25rem) {
	.primary-navigation {
		font-size: 1.5rem;
	}
}

.js-loading-error .primary-navigation,
.no-js .primary-navigation {
	color: var(--color-blue);
}

.primary-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.primary-navigation__item {
	display: inline-block;
}

.primary-navigation__title {
	color: currentcolor;
	display: block;
	text-decoration: none;
}

.t-jac .primary-navigation__title {
	color: var(--color-black);
}

.js-loading-error .t-jac .primary-navigation__title,
.no-js .t-jac .primary-navigation__title {
	color: var(--color-black);
}

.primary-navigation__title:hover {
	color: var(--color-purple);
}

.t-jac .primary-navigation__title:hover {
	color: var(--color-orange);
}

.primary-navigation__item--active .primary-navigation__title {
	color: var(--color-purple);
}

.t-jac .primary-navigation__item--active .primary-navigation__title {
	color: var(--color-orange);
}

.primary-navigation__title__label {
	padding: 0.625rem 0.5rem;
}

@media (width >= 86.25rem) {
	.primary-navigation__title__label {
		padding: 0.625rem 1.125rem;
	}
}
