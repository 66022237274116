.infosheet__row {
	box-sizing: border-box;
	margin-bottom: 1rem;
	padding-left: 2rem;
	position: relative;
}

.infosheet__row svg {
	position: absolute;
	top: 0.25rem;
	left: 0;
	width: 1rem;
	height: 1rem;
}

.infosheet__row svg:not(.icon) {
	display: none;
}

.infosheet table {
	margin-top: 2rem;
}

.infosheet__map {
	background-color: var(--color-blue-lightened);
	box-sizing: border-box;
	display: block;
	padding-bottom: 70%;
	position: relative;
	width: 100%;
}

@media (width < 48rem) {
	.infosheet__map {
		display: none;
	}
}

.infosheet__map__map {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
