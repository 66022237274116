.testimonials {
	background-color: var(--color-blue-light);
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 48rem) {
	.testimonials {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.testimonials {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.testimonials {
		padding-top: 5.625rem;
		padding-bottom: 5.625rem;
	}
}

.campaign-banner + .testimonials {
	background-color: var(--color-grey-light);
}

.testimonials__wrapper {
	margin-right: auto;
	margin-left: auto;
	position: relative;
	width: 92.5%;
	max-width: 1164px;
}

@media (width >= 48rem) {
	.testimonials__wrapper {
		width: 83.3333%;
	}
}

@media (width >= 64rem) {
	.testimonials__wrapper {
		display: flex;
	}
}

.testimonials__info {
	margin-bottom: 2rem;
}

@media (width >= 48rem) {
	.testimonials__info {
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.testimonials__info {
		box-sizing: border-box;
		flex: 0 0 50%;
		margin-bottom: 0;
		padding-right: 2em;
	}
}

@media (width >= 86.25rem) {
	.testimonials__info {
		flex: 0 0 40%;
		padding-right: 3em;
	}
}

.testimonials__info__title {
	margin-bottom: 1.5em;
}

@media (width >= 64rem) {
	.testimonials__list {
		flex: 0 0 50%;
	}
}

@media (width >= 86.25rem) {
	.testimonials__list {
		flex: 0 0 60%;
	}
}

.testimonials__item {
	background-color: var(--color-blue);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
}

.testimonials__item::after {
	background-color: var(--color-blue);
	content: "";
	opacity: 0.25;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 128ms linear;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.testimonials__item:hover::after {
	opacity: 0.5;
}

.testimonials__item__wrapper {
	box-sizing: border-box;
	color: var(--color-white);
	display: flex;
	flex-direction: column;
	padding: 2em 1.25em;
	justify-content: center;
	align-items: center;
	position: absolute;
	text-align: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

@media (width >= 48rem) {
	.testimonials__item__wrapper {
		padding: 5em 2.5em;
	}
}

@media (width >= 64rem) {
	.testimonials__item__wrapper {
		padding: 3em 2.5em;
	}
}

@media (width >= 86.25rem) {
	.testimonials__item__wrapper {
		padding: 7.5em 6.25em;
	}
}

.testimonials__item__title {
	color: currentColor;
	font-family: var(--font-serif);
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.33;
	margin-bottom: 2rem;
}

@media (width >= 48rem) {
	.testimonials__item__title {
		font-size: 1.875rem;
	}
}

@media (width >= 64rem) {
	.testimonials__item__title {
		font-size: 1.5rem;
	}
}

@media (width >= 86.25rem) {
	.testimonials__item__title {
		font-size: 1.875rem;
	}
}

@media (width >= 48rem) {
	.testimonials__item__content,
	.testimonials__item__text-link {
		font-size: 1.125rem;
	}
}

@media (width >= 64rem) {
	.testimonials__item__content,
	.testimonials__item__text-link {
		font-size: 1rem;
	}
}

@media (width >= 86.25rem) {
	.testimonials__item__content,
	.testimonials__item__text-link {
		font-size: 1.25rem;
	}
}

.testimonials__item__text-link {
	font-size: 1rem;
	text-decoration: underline;
	transition: color 96ms linear;
}

@media (width >= 86.25rem) {
	.testimonials__item__text-link {
		font-size: 1.125rem;
	}
}

.testimonials__item__image {
	display: block;
	height: auto;
}
