.region-select {
	margin-top: 2rem;
	width: 100%;
}

.region-select__row {
	width: 100%;
}

.region-select__row:not(:first-child) {
	margin-top: 1rem;
}

@media (width >= 48rem) {
	.region-select__row.region-select__row--submit {
		margin-top: 2rem;
	}
}

.region-select__label {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	font-size: 0.825rem;
	line-height: 1.6666em;
	margin-bottom: 0.5rem;
}

.region-select__field,
.region-select__field-container {
	width: 100%;
}

@media (width >= 64rem) {
	.region-select__field {
		width: 75%;
	}
}

.region-select__button {
	display: inline-block;
	vertical-align: top;
}

.region-select__submit {
	width: 100%;
}

.region-select__submit:hover {
	background-color: var(--color-blue);
	color: var(--color-white);
}

@media (width >= 48rem) {
	.region-select__submit {
		display: block;
		width: auto;
	}
}
