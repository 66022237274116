.image__image {
	display: block;
	width: 100%;
}

.image__caption {
	display: inline-block;
	font-size: 0.875rem;
	line-height: 1.75;
	padding-top: 0.25rem;
	text-align: right;
	width: 100%;
}
