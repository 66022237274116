.button {
	appearance: none;
	background-color: var(--color-purple);
	border: none;
	color: var(--color-white);
	cursor: pointer;
	display: block;
	font-family: var(--font-serif);
	font-weight: 500;
	margin-top: auto;
	padding: 1rem 2rem;
	text-decoration: none;
	transition:
		color 96ms linear,
		background-color 96ms linear;
	width: max-content;
}

.t-jac .button {
	background-color: var(--color-orange);
	color: var(--color-white);
}

.button:hover {
	background-color: var(--color-white);
	color: var(--color-purple);
}

.t-jac .button:hover {
	background-color: var(--color-white);
	color: var(--color-orange);
}

.flexible-content p > .button:hover {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.wrapper--centered .button {
	margin-right: auto;
	margin-left: auto;
}

.button.button--disabled {
	background-color: var(--color-grey-light);
	cursor: default;
	pointer-events: none;
}
