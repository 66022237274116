@property --home-hero--background-color {
	syntax: "<color>";
	inherits: true;
	initial-value: #000;
}

@property --home-hero--text-color {
	syntax: "<color>";
	inherits: true;
	initial-value: #fff;
}

@property --home-hero--link-color {
	syntax: "<color>";
	inherits: true;
	initial-value: #fff;
}

@property --home-hero--cover-color {
	syntax: "<color>";
	inherits: true;
	initial-value: transparent;
}

[data-hero-item] {
	--home-hero--background-color: var(--color-blue);
	--home-hero--cover-color--hover: rgba(0, 0, 0, 0.15);
	--home-hero--cover-color: transparent;
	--home-hero--link-color--hover: var(--color-white);
	--home-hero--link-color: var(--color-white);
	--home-hero--text-color--hover: var(--color-white);
	--home-hero--text-color: var(--color-white);
}

[data-hero-item]:is(:hover, :focus-visible) {
	--home-hero--cover-color: var(--home-hero--cover-color--hover);
	--home-hero--link-color: var(--home-hero--link-color--hover);
	--home-hero--text-color: var(--home-hero--text-color--hover);
}

[data-title-color="blue"] {
	--home-hero--background-color: var(--color-blue-light);
	--home-hero--cover-color--hover: rgba(255, 255, 255, 0.4);
	--home-hero--link-color--hover: var(--color-purple);
	--home-hero--link-color: var(--color-blue);
	--home-hero--text-color--hover: var(--color-blue);
	--home-hero--text-color: var(--color-blue);
}

[data-hero-illustration="caw"] {
	--home-hero--background-color: var(--color-blue-light);
	--home-hero--cover-color--hover: rgba(255, 255, 255, 0.6);
	--home-hero--link-color--hover: var(--color-purple);
	--home-hero--link-color: var(--color-blue);
	--home-hero--text-color--hover: var(--color-blue);
	--home-hero--text-color: var(--color-blue);
}

[data-hero-illustration="jac"] {
	--home-hero--background-color: var(--color-white);
	--home-hero--cover-color--hover: rgba(0, 0, 0, 0.1);
	--home-hero--link-color--hover: var(--color-orange);
	--home-hero--link-color: var(--color-orange);
	--home-hero--text-color--hover: var(--color-orange);
	--home-hero--text-color: var(--color-orange);
}
