.breadcrumb {
	color: var(--color-grey-b);
	display: flex;
	font-size: 0.8125rem;
	list-style: none;
	margin: 0 auto;
	padding: 0 0.375rem;
	width: 94%;
	max-width: 1600px;
}

.breadcrumb__item {
	flex-shrink: 1;
	font-weight: 500;
}

.breadcrumb__item:not(:last-child) {
	position: relative;
}

.breadcrumb__item:not(:last-child)::after {
	content: "›";
	font-family: Arial, sans-serif;
	font-weight: 100;
	opacity: 0.625;
	position: absolute;
	top: 10px;
	right: -2px;
}

.breadcrumb__item .icon {
	fill: currentcolor;
	transform: translateY(-2px);
	vertical-align: middle;
	width: 0.8125rem;
	height: 0.8125rem;
}

.breadcrumb__item__link {
	box-sizing: border-box;
	color: currentcolor;
	display: block;
	overflow: hidden;
	padding: 0.625rem;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 200px;
}

.breadcrumb__item__link:hover {
	color: var(--color-blue);
	text-decoration: underline;
}

.t-jac .breadcrumb__item__link {
	color: var(--color-grey-b);
}

@media (width >= 86.25rem) {
	.breadcrumb__item__link {
		max-width: 300px;
	}
}

@media (width >= 105rem) {
	.breadcrumb__item__link {
		max-width: 400px;
	}
}
