.faq__title {
	font-size: 1.25rem;
	margin-bottom: 1em;
}

@media (width >= 48rem) {
	.faq__title {
		font-size: 1.5rem;
	}
}

.faq__list {
	border-bottom: 1px solid var(--color-grey-light);
}

.faq__rating {
	color: var(--color-grey);
	font-family: var(--font-sans);
	padding-top: 1em;
	justify-content: flex-start;
}

@media (width >= 48rem) {
	.faq__rating {
		display: flex;
		flex-wrap: wrap;
		font-size: 0.875rem;
		align-items: center;
	}
}

.faq__rating > p {
	font-size: inherit;
	margin-top: 0.625rem;
}

@media (width < 48rem) {
	.faq__rating > p {
		margin-bottom: 0.625rem;
	}
}

@media (width >= 48rem) {
	.faq__rating > p {
		line-height: 2.125rem;
		margin-right: 1rem;
	}
}
