.gravity-forms .gform_heading {
	border-bottom: 1px solid var(--color-grey-light);
	margin-bottom: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 64rem) {
	.gravity-forms .gform_heading {
		margin-bottom: 3rem;
		padding-bottom: 3rem;
	}
}

.gravity-forms .gform_heading .gform_title {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.5rem;
	margin-bottom: 0;
}

@media (width >= 48rem) {
	.gravity-forms .gform_heading .gform_title {
		font-size: 1.875rem;
	}
}

@media (width >= 86.25rem) {
	.gravity-forms .gform_heading .gform_title {
		font-size: 2.25rem;
	}
}

.gravity-forms .gform_heading .gform_description {
	color: var(--color-grey);
}

.gravity-forms .gform_heading .gform_description:not(:empty) {
	display: inline-block;
	margin-top: 2rem;
}

@media (width >= 86.25rem) {
	.gravity-forms .gform_heading .gform_description:not(:empty) {
		max-width: 50%;
	}
}

.gravity-forms .gf_progressbar_wrapper {
	border-bottom: 1px solid var(--color-grey-light);
	margin-bottom: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 64rem) {
	.gravity-forms .gf_progressbar_wrapper {
		display: flex;
		margin-bottom: 3rem;
		padding-bottom: 3rem;
		align-items: center;
	}
}

.gravity-forms .gf_progressbar_wrapper .gf_progressbar_title {
	color: var(--color-black);
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: 600;
}

@media (width >= 64rem) {
	.gravity-forms .gf_progressbar_wrapper .gf_progressbar_title {
		margin-right: 1rem;
		margin-bottom: 0;
	}
}

.gravity-forms .gf_progressbar_wrapper .gf_progressbar {
	background-color: var(--color-grey-light);
	border-radius: 0.5625rem;
	overflow: hidden;
	height: 1.125rem;
}

@media (width >= 64rem) {
	.gravity-forms .gf_progressbar_wrapper .gf_progressbar {
		flex-grow: 1;
	}
}

.gravity-forms .gf_progressbar_wrapper .gf_progressbar_percentage {
	background-color: var(--color-blue);
	border-radius: 0.5625rem;
	text-align: center;
	max-width: 100%;
	height: 1.125rem;
}

.gravity-forms .gf_progressbar_wrapper .gf_progressbar_percentage span {
	color: var(--color-white);
	display: block;
	font-size: 0.75rem;
	line-height: 1.125rem;
	width: 100%;
	height: 1.125rem;
}

.gravity-forms .validation_error {
	border-bottom: 1px solid var(--color-grey-light);
	color: #d10000;
	margin-bottom: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 64rem) {
	.gravity-forms .validation_error {
		margin-bottom: 3rem;
		padding-bottom: 3rem;
	}
}

.gravity-forms .gform_confirmation_wrapper {
	border-top: 1px solid var(--color-grey-light);
	border-bottom: 1px solid var(--color-grey-light);
	color: var(--color-blue);
	font-family: var(--font-serif);
	font-size: 1.125rem;
	font-weight: 500;
	margin: 2rem 0;
	padding: 2rem 0;
	text-align: center;
}

@media (width >= 48rem) {
	.gravity-forms .gform_confirmation_wrapper {
		font-size: 1.25rem;
	}
}

@media (width >= 64rem) {
	.gravity-forms .gform_confirmation_wrapper {
		margin: 3rem 0;
		padding: 3rem 0;
	}
}

.gravity-forms .gform_fields {
	list-style: none;
	margin: 0;
	padding: 0;
}

.gravity-forms .gform_fields .gfield {
	display: block;
	margin-bottom: 1rem;
	width: auto;
}

@media (width >= 48rem) {
	.gravity-forms .gform_fields .gfield {
		margin-bottom: 2rem;
	}
}

.gravity-forms .gform_fields .gfield.gform_hidden {
	display: none;
}

.gravity-forms .gform_fields .gsection {
	border-top: 1px solid var(--color-grey-light);
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding-top: 1rem;
	width: 100%;
}

@media (width >= 64rem) {
	.gravity-forms .gform_fields .gsection {
		margin-top: 3rem;
	}
}

.gravity-forms .gform_fields .gsection .gsection_title {
	font-size: 1.125rem;
	margin: 0;
}

@media (width >= 48rem) {
	.gravity-forms .gform_fields .gsection .gsection_title {
		font-size: 1.25rem;
	}
}

.gravity-forms .gform_page:first-child .gform_fields .gsection {
	border-top: 0;
	padding-top: 0;
}

.gravity-forms input,
.gravity-forms select,
.gravity-forms textarea {
	appearance: none;
	background-color: transparent;
	border-color: var(--color-grey);
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: 600;
	margin: 0;
	width: 100%;
}

.gravity-forms input:focus,
.gravity-forms select:focus,
.gravity-forms textarea:focus {
	background-color: rgb(0 0 0 / 0.035);
	border-color: rgb(0 0 0 / 0.7);
	outline: 0;
}

@media (width >= 48rem) {
	.gravity-forms input.small,
	.gravity-forms select.small,
	.gravity-forms textarea.small {
		width: 50%;
	}
}

@media (width >= 48rem) {
	.gravity-forms input.medium,
	.gravity-forms select.medium,
	.gravity-forms textarea.medium,
	.gravity-forms input.datepicker {
		width: 75%;
	}
}

.gravity-forms input::placeholder,
.gravity-forms select::placeholder,
.gravity-forms textarea::placeholder {
	font-weight: 400;
}

.gravity-forms .gfield_error input,
.gravity-forms .gfield_error select,
.gravity-forms .gfield_error textarea {
	border-color: #d10000;
}

.gravity-forms label {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	font-size: 0.825rem;
	line-height: 1.6666em;
	margin-bottom: 0.5rem;
}

.gravity-forms label .gfield_required {
	color: #d10000;
}

.gravity-forms .instruction {
	color: var(--color-grey);
	display: inline-block;
	font-size: 0.825rem;
	line-height: 1.6666em;
	margin-top: 0.5rem;
}

.gravity-forms .gfield_description,
.gravity-forms div.description {
	box-sizing: border-box;
	display: inline-block;
	font-size: 0.825rem;
	line-height: 1.6666em;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.gravity-forms input {
	line-height: 3rem;
	padding: 0 1rem;
	height: 3rem;
}

.gravity-forms input[type="button"],
.gravity-forms input[type="submit"] {
	border-color: rgb(0 0 0 / 0);
	color: var(--color-white);
	display: block;
	transition: background-color 96ms linear;
	width: max-content;
	min-width: 200px;
}

.gravity-forms input[type="button"]:focus,
.gravity-forms input[type="submit"]:focus {
	border-color: rgb(0 0 0 / 0.7);
}

.gravity-forms input[type="button"] {
	background-color: var(--color-blue);
}

.gravity-forms input[type="button"]:hover,
.gravity-forms input[type="button"]:focus {
	background-color: var(--color-purple);
}

.gravity-forms input[type="submit"] {
	background-color: var(--color-purple);
}

@media (width < 48rem) {
	.gravity-forms input[type="submit"] {
		width: 100%;
	}
}

.t-jac .gravity-forms input[type="submit"] {
	background-color: var(--color-orange);
}

.gravity-forms input[type="submit"]:hover,
.gravity-forms input[type="submit"]:focus {
	background-color: var(--color-blue);
}

.gravity-forms select:not([multiple]) {
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 48"><g><polygon points="0,21 12,0 24,21"/></g><g><polygon points="0,27 12,48 24,27"/></g></svg>');
	background-position: calc(100% - 16px) 50%;
	background-repeat: no-repeat;
	background-size: 8px 16px;
	line-height: 3rem;
	padding: 0 1rem;
	height: 3rem;
}

.gravity-forms select[multiple] option {
	box-sizing: border-box;
	display: block;
	padding: 0.5rem 1rem;
}

.gravity-forms textarea {
	padding: 1rem;
	resize: vertical;
	min-height: 9rem;
}

.gravity-forms .gfield_checkbox,
.gravity-forms .gfield_radio {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
}

.gravity-forms .gfield_checkbox li,
.gravity-forms .gfield_radio li {
	margin-right: 1rem;
}

.gravity-forms .gfield_checkbox label,
.gravity-forms .gfield_radio label {
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	cursor: pointer;
	display: block;
	font-family: var(--font-sans);
	font-size: 0.9375rem;
	padding: 1rem 0 1rem 1.4375rem;
	position: relative;
	transition:
		background-color 96ms ease-out,
		color 96ms linear;
	user-select: none;
}

.gravity-forms .gfield_checkbox label:active,
.gravity-forms .gfield_checkbox label:focus,
.gravity-forms .gfield_radio label:active,
.gravity-forms .gfield_radio label:focus {
	background-color: rgb(0 0 0 / 0.025);
	transition: none;
}

.gravity-forms .gfield_checkbox label::before,
.gravity-forms .gfield_radio label::before {
	border: 1px solid rgb(0 0 0 / 1);
	box-sizing: border-box;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(calc(-50% + 1px));
	width: 0.75em;
	height: 0.75em;
}

.gravity-forms .gfield_checkbox label:hover,
.gravity-forms .gfield_radio label:hover {
	color: var(--color-blue);
}

.gravity-forms .gfield_checkbox input,
.gravity-forms .gfield_radio input {
	display: none;
}

.gravity-forms .gfield_checkbox input[type="radio"] ~ label::before,
.gravity-forms .gfield_radio input[type="radio"] ~ label::before {
	border-radius: 50%;
}

.gravity-forms .gfield_checkbox input[type="checkbox"] ~ label::before,
.gravity-forms .gfield_radio input[type="checkbox"] ~ label::before {
	border-radius: 0;
}

.gravity-forms .gfield_checkbox input:checked ~ label::before,
.gravity-forms .gfield_radio input:checked ~ label::before {
	background-color: var(--color-black);
	border: 0;
}

.gravity-forms .ginput_container_fileupload input {
	border-style: dashed;
	padding: 3rem 2rem;
	height: auto;
}

.gravity-forms .ginput_preview .gform_delete {
	float: left;
	margin-right: 0.5rem;
	opacity: 0;
	padding-top: 0.1875rem;
	width: 1rem;
	height: 1rem;
}

.gravity-forms .ginput_preview .gform_delete:hover {
	cursor: pointer;
}

.gravity-forms .ginput_preview strong {
	line-height: 1.375rem;
	position: relative;
}

.gravity-forms .ginput_preview strong::before {
	color: #d10000;
	content: "×";
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1.375rem;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: -1.5rem;
}

.gravity-forms .ginput_preview strong::after {
	clear: both;
	content: " ";
	display: table;
}

.gravity-forms table,
.gravity-forms td,
.gravity-forms th {
	border: 0;
	margin: 0;
	padding-top: 0;
	padding-left: 0;
}

.gravity-forms table tr:last-child th,
.gravity-forms table tr:last-child td {
	padding-bottom: 0;
}

.gravity-forms .gfield_list {
	margin-bottom: 1rem;
}

@media (width >= 48rem) {
	.gravity-forms .gfield_list {
		margin-bottom: 2rem;
	}
}

.gravity-forms .ginput_complex span {
	margin-bottom: 1rem;
}

@media (width >= 48rem) {
	.gravity-forms .ginput_complex span {
		margin: 0 1rem;
	}
}

.gravity-forms .ginput_complex span:first-child {
	margin-left: 0;
}

.gravity-forms .ginput_complex span:last-child {
	margin-right: 0;
}

.gravity-forms .clear-multi {
	display: flex;
}

.gravity-forms .clear-multi div {
	width: 100%;
}

.gravity-forms .clear-multi div:not(:last-child) {
	margin-right: 1rem;
}

.gravity-forms .gform_page_footer {
	text-align: center;
}

@media (width >= 48rem) {
	.gravity-forms .gform_page_footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.gravity-forms .gform_page_footer > input {
	margin-bottom: 1rem;
}

@media (width >= 48rem) {
	.gravity-forms .gform_page_footer > input {
		margin: 0;
	}
}

.gravity-forms .gform_page_footer > input:first-child {
	margin-left: 0;
}

.gravity-forms .gform_page_footer > input:last-child {
	margin-right: 0;
}

.gfield_visibility_hidden {
	display: none !important;
	pointer-events: none;
	user-select: none;
}

/* Hide "Honeypot" fields */
.gravity-forms .gform_fields > .gfield.gform_validation_container {
	margin: 0;
	opacity: 0;
	overflow: hidden;
	padding: 0;
	pointer-events: none;
	width: 1px;
	height: 1px;
}

.gform_body .screen-reader-text {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.ui-datepicker {
	background-color: var(--color-white);
	border: 1px solid var(--color-grey-light);
	padding: 20px;
}
