.location-region-selector {
	display: block;
	margin-top: 2rem;
}

@media (width >= 48rem) {
	.location-region-selector {
		margin-top: 2.25rem;
	}
}

@media (width >= 64rem) {
	.location-region-selector {
		margin-top: 2.5rem;
	}
}

@media (width >= 86.25rem) {
	.location-region-selector {
		margin-top: 3.25rem;
	}
}

.location-region-selector__summary {
	cursor: pointer;
	font-size: 1rem;
	font-weight: 400;
}

.location-region-selector__summary:hover {
	color: var(--color-purple);
}

.location-region-selector__list {
	list-style: none;
	margin-block-start: 0.5rem;
	margin-block-end: 0;
	padding-left: 1rem;
}

.location-region-selector__list__item {
	font-size: 1rem;
	font-weight: 400;
}

.location-region-selector__list__item :any-link:hover,
.location-region-selector__list__item :any-link[data-is-active] {
	color: var(--color-blue);
}
