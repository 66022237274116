.location-overview {
	margin-right: auto;
	margin-left: auto;
	position: relative;
	width: 92.5%;
	max-width: 672px;
}

@media (width >= 48rem) {
	.location-overview {
		width: 66.6666%;
	}
}

@media (width >= 64rem) {
	.location-overview {
		border-collapse: collapse;
		display: table;
		width: 83.3333%;
		max-width: 1400px;
	}
}

.location-overview__header {
	color: var(--color-blue);
	font-weight: 600;
}

@media (width < 64rem) {
	.location-overview__header {
		display: none;
	}
}

.location-overview__header,
.location-overview__row {
	border-bottom: 1px solid var(--color-grey);
}

@media (width < 64rem) {
	.location-overview__header,
	.location-overview__row {
		padding-top: 1rem;
		padding-right: 3.625rem;
		padding-bottom: 1rem;
		position: relative;
	}
}

@media (width >= 64rem) {
	.location-overview__header,
	.location-overview__row {
		display: table-row;
	}
}

@media (width >= 64rem) {
	.location-overview__name,
	.location-overview__street,
	.location-overview__postal,
	.location-overview__city,
	.location-overview__region,
	.location-overview__link {
		display: table-cell;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

.location-overview__street,
.location-overview__postal,
.location-overview__city,
.location-overview__region {
	font-weight: 600;
}

@media (width < 64rem) {
	.location-overview__street,
	.location-overview__postal,
	.location-overview__city,
	.location-overview__region {
		display: inline-block;
	}
}

@media (width < 64rem) {
	.location-overview__street:empty,
	.location-overview__postal:empty,
	.location-overview__city:empty,
	.location-overview__region:empty {
		display: none;
	}
}

@media (width < 64rem) {
	.location-overview__street {
		display: inline-block;
		margin-top: 1rem;
	}
}

@media (width < 64rem) {
	.location-overview__street:not(:empty)::after {
		content: ",";
	}
}

@media (width >= 64rem) {
	.location-overview__name,
	.location-overview__street,
	.location-overview__postal,
	.location-overview__city,
	.location-overview__region {
		padding-right: 2rem;
	}
}

.location-overview__name {
	margin-bottom: 0;
}

.location-overview__name__link {
	text-decoration: none;
}

.location-overview__name__link:hover,
.location-overview__name__link:focus {
	text-decoration: underline;
}

@media (width < 64rem) {
	.location-overview__region {
		display: none;
	}
}

.locations-overview__link {
	border: 2px solid var(--color-purple);
	border-radius: 50%;
	box-sizing: border-box;
	color: var(--color-purple);
	display: block;
	font-family: var(--font-serif);
	font-size: 1.875rem;
	font-weight: 400;
	line-height: 2.125rem;
	text-align: center;
	text-decoration: none;
	transition:
		background-color 96ms linear,
		color 96ms linear;
	width: 2.625rem;
	height: 2.625rem;
}

@media (width < 64rem) {
	.locations-overview__link {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(0, -50%);
	}
}

.t-jac .locations-overview__link {
	border-color: var(--color-orange);
	color: var(--color-orange);
}

.locations-overview__link:hover {
	background-color: var(--color-purple);
	color: var(--color-white);
}

.t-jac .locations-overview__link:hover {
	background-color: var(--color-orange);
}
