.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	text-align: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
}

.modal__close-button {
	-mrh-resets: button;
	color: var(--color-blue);
	font-size: 1.125rem;
	font-weight: 600;
	text-decoration: underline;
	transition: color 96ms linear;
}

.modal__close-button:hover {
	color: var(--color-purple);
	cursor: pointer;
}

.t-jac .modal__close-button:hover {
	color: var(--color-orange);
}

.modal__video {
	display: block;
	margin: 2rem auto;
	padding-bottom: 56.25%;
	position: relative;
	width: 720px;
}

@media (width >= 86.25rem) and (height >= 43.75rem) {
	.modal__video {
		width: 960px;
	}
}

@media (width >= 105rem) and (height >= 50rem) {
	.modal__video {
		width: 1080px;
	}
}

.modal__video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.modal__video p {
	background-color: var(--color-purple);
	color: var(--color-white);
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.modal--video .modal__title {
	margin: 0;
}

.modal__inner-content {
	background-color: var(--color-blue-light);
	margin-top: 1rem;
	margin-right: auto;
	margin-left: auto;
	overflow-y: auto;
	width: calc(100% - 2rem);
	max-height: 80vh;
}

@media (width >= 23.4375rem) {
	.modal__inner-content {
		margin-top: 2rem;
	}
}

@media (width < 48rem) {
	.modal__inner-content {
		padding: 1rem;
	}
}

@media (width >= 48rem) {
	.modal__inner-content {
		width: 75%;
	}
}

@media (width >= 86.25rem) {
	.modal__inner-content {
		width: 100%;
	}
}

@media (width >= 48rem) {
	.modal__intro {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.modal__intro {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.modal__intro {
		padding-top: 5.625rem;
		padding-bottom: 5.625rem;
	}
}

@media (width < 48rem) {
	.modal__intro__content {
		display: none;
	}
}

.modal__intro__content:not(:last-child) {
	margin-bottom: 1rem;
}

@media (width < 48rem) {
	.modal__intro__link {
		background-color: var(--color-blue);
		padding-top: 1rem;
	}
}

@media (width < 48rem) {
	.modal__intro__link a {
		color: var(--color-white);
		font-size: 0.9375rem;
		line-height: 1.25;
	}
}

@media (23.4375rem <= width < 48rem) {
	.modal__intro__link {
		padding-top: 2rem;
	}
}
