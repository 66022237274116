.tabs {
	display: block;
}

.tabs__tablist {
	display: flex;
	margin: -0.25rem auto;
	justify-content: center;
	position: relative;
	width: 92.5%;
	max-width: 980px;
}

.tabs__tablist__button {
	-mrh-resets: button;
	background-color: var(--color-blue-lightened);
	border: none;
	color: var(--color-blue);
	cursor: pointer;
	flex-grow: 1;
	font-family: var(--font-serif);
	font-size: 1rem;
	font-weight: 500;
	margin: 0 0.25rem;
	padding: 1.375rem;
}

.tabs__tablist__button[aria-selected="true"] {
	background-color: var(--color-blue-light);
	color: var(--color-blue);
}

.tabs__button.is-active strong {
	color: var(--color-blue);
}

.tabs__button strong {
	font-weight: 500;
}

.tabs__button span {
	opacity: 0.5;
}

@media (width < 64rem) {
	.tabs__button span {
		display: none;
	}
}

.tabs__panels {
	background-color: var(--color-blue-light);
	padding: 3rem 0;
	position: relative;
}

@supports (content-visibility: hidden) and (display: revert) {
	.tabs [hidden][hidden="until-found"] {
		content-visibility: hidden;
		display: revert;
	}
}
