.home-hero {
	display: grid;
	grid-gap: 1rem;
	grid-template-areas:
		"primary"
		"secondary"
		"tertiary";
	grid-template-rows: 100vw 58.75vw 58.75vw;
	grid-template-columns: 1fr;
	margin-top: 1rem;
	margin-bottom: 2rem;
}

@media (width >= 48rem) {
	.home-hero {
		grid-auto-flow: column dense;
		grid-template-areas:
			"primary primary"
			"secondary tertiary";
		grid-template-rows: 62.5vw 31.35vw;
		grid-template-columns: repeat(2, 1fr);
		margin-top: 2rem;
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.home-hero {
		grid-template-areas:
			"primary secondary"
			"primary tertiary";
		grid-template-rows: repeat(2, 18.65vw);
		grid-template-columns: 9fr 5fr;
		margin-top: 0;
		margin-bottom: 4rem;
	}

	/* Admins can choose to place primary block on the right from medium up. */
	[data-primary-block-position="right"] {
		grid-template-areas:
			"secondary primary"
			"tertiary primary";
		grid-template-columns: 5fr 9fr;
	}
}

@media (width >= 86.25rem) {
	.home-hero {
		grid-gap: 2.25rem;
		margin-bottom: 5.625rem;
	}
}

@media (width >= 98.875rem) {
	.home-hero {
		grid-template-rows: repeat(2, calc(98.875rem * 0.1865));
	}
}

[data-grid-area="primary"] {
	grid-area: primary;
}

[data-grid-area="secondary"] {
	grid-area: secondary;
}

[data-grid-area="tertiary"] {
	grid-area: tertiary;
}

.home-hero__link {
	background-color: var(--home-hero--background-color);
	transition: background-color 96ms linear;
}

.home-hero__title {
	color: var(--home-hero--text-color);
	transition: color 96ms linear;
}

.home-hero__text-link {
	color: var(--home-hero--link-color);
	transition: color 96ms linear;
}

/* #region Link */
.home-hero__link {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border: 0.5rem solid var(--color-white);
	box-sizing: border-box;
	color: currentColor;
	display: flex;
	padding: 1.75em;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	text-decoration: none;
	width: 100%;
	height: 100%;
}

@media (width >= 48rem) {
	.home-hero__link {
		padding: 2.5em;
	}
}

@supports (display: grid) {
	.home-hero__link {
		border: 0;
		padding: 1.25em;
	}

	@media (width >= 48rem) {
		.home-hero__link {
			padding: 2em;
		}
	}
}

[data-grid-area="primary"] .home-hero__link {
	padding: 1.25em;
	justify-content: flex-start;
	align-items: flex-end;
	text-align: left;
}

@media (width >= 48rem) {
	[data-grid-area="primary"] .home-hero__link {
		padding: 2em 3em;
	}
}

@media (width >= 64rem) {
	[data-grid-area="primary"] .home-hero__link {
		padding: 3em 25% 3em 4em;
	}
}

@media (width >= 86.25rem) {
	[data-grid-area="primary"] .home-hero__link {
		padding: 4.6875em 33.3333% 4.6875em 5.625em;
	}
}

.home-hero__link::before {
	background-color: var(--home-hero--cover-color);
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	transition: background-color 96ms linear;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.home-hero__link__line {
	background-image: url("../../images/line.svg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(25% + 1.25em);
	z-index: 2;
}

@media (width >= 48rem) {
	.home-hero__link__line {
		height: calc(25% + 2em);
	}
}

@media (width >= 86.25rem) {
	.home-hero__link__line {
		background-position: bottom center;
	}
}

[data-hero-illustration="jac"] .home-hero__link__line {
	background-image: url("../../images/line-jac.svg");
}

.home-hero__link__content {
	position: relative;
	z-index: 3;
}
/* #endregion Link */

/* #region Title */
.home-hero__title {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-serif);
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.3333;
	margin-top: 0;
	margin-bottom: 1rem;
}

@media (width >= 48rem) {
	.home-hero__title {
		font-size: 1.5rem;
	}
}

@media (64rem <= width < 86.25rem) {
	.home-hero__title {
		line-height: 1.1;
		margin-bottom: 0.375em;
	}
}

@media (width >= 86.25rem) {
	.home-hero__title {
		font-size: 1.875rem;
		margin-bottom: 1rem;
	}
}

[data-grid-area="primary"] .home-hero__title {
	font-size: 1.875rem;
	line-height: 1.33;
	margin-bottom: 1em;
}

@media (width >= 48rem) {
	[data-grid-area="primary"] .home-hero__title {
		font-size: 2rem;
	}
}

@media (width >= 86.25rem) {
	[data-grid-area="primary"] .home-hero__title {
		font-size: 2.25rem;
	}
}

.home-hero__title:only-child {
	margin-bottom: 0;
}
/* #endregion Title */

/* #region Text link */
.home-hero__text-link {
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 1rem;
	margin: 0;
	text-decoration: underline;
}

@media (width >= 86.25rem) {
	.home-hero__text-link {
		font-size: 1.125rem;
	}
}
/* #endregion Text link */

/* #region Image */
.home-hero__media {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.home-hero__media__image {
	object-fit: cover;
	height: 100%;
}
/* #endregion Image */
