.form {
}

.form__group {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form__group:not(*:last-of-type) {
	margin-bottom: 1.25rem;
}

@media (width < 48rem) {
	.form__group {
		flex-wrap: wrap;
	}
}

.form__label {
	flex-grow: 0;
	flex-shrink: 0;
	width: 16rem;
}

@media (width < 48rem) {
	.form__label {
		margin-bottom: 1rem;
	}
}

@media (width >= 48rem) {
	.form__label {
		flex-shrink: 1;
	}
}

.form__input {
	appearance: none;
	background-color: var(--color-white);
	border: 1px solid var(--color-grey-light);
	border-radius: 0;
	box-sizing: border-box;
	color: var(--color-blue);
	flex-grow: 1;
	flex-shrink: 0;
	font-family: var(--font-sans);
	font-size: 1rem;
	padding: 1rem;
}

@media (width < 48rem) {
	.form__input {
		flex-basis: 100%;
	}
}

@media (width >= 48rem) {
	.form__input {
		flex-shrink: 1;
	}
}

.form__input::placeholder {
	color: var(--color-grey);
}

.form__input:focus {
	border-color: var(--color-blue);
	box-shadow: 0 0 2px var(--color-blue);
	outline: 0;
}

.form__input--select {
	background-image: url("../../images/dropdown-arrow.svg");
	background-position: calc(100% - 1rem) center;
	background-repeat: no-repeat;
	background-size: 14px 8px;
	color: var(--color-grey);
	line-height: 3rem;
	padding: 0 1rem;
	height: 3rem;
}

.form__group--has-textarea {
	flex-wrap: wrap;
}

.form__group--has-textarea .form__label {
	flex-shrink: 0;
	margin-top: 1rem;
	margin-bottom: 1rem;
	width: 100%;
}

.form__group--has-textarea textarea {
	min-height: 200px;
}

.form__action {
	margin: 3.75rem auto;
}

.form__action .button {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: block;
	font-size: 1rem;
	font-weight: 400;
	margin: 0 auto;
}

@media (width >= 48rem) {
	.form__action .button {
		font-size: 1.25rem;
	}
}

@media (width < 64rem) {
	.form__action .button {
		width: 100%;
	}
}

@media (width >= 86.25rem) {
	.form__action .button {
		font-size: 1.5rem;
	}
}
