.go-to-parent-context {
	background-color: var(--color-orange);
	margin: 2rem 0;
	padding: 1.25rem 34px 1.75rem 34px;
	text-align: center;
}

@media (width >= 48rem) {
	.go-to-parent-context {
		display: flex;
		margin: 4rem 0;
		padding: 0.875rem 20px;
		justify-content: space-between;
		align-items: center;
	}
}

@media (width >= 90rem) {
	.go-to-parent-context {
		margin: 8.125rem 0;
		padding: 1.125rem 20px;
	}
}

/* #region Title + Link */
@media (width < 48rem) {
	.go-to-parent-context__title {
		margin-bottom: 0.9375rem;
	}
}

.go-to-parent-context__title p,
.go-to-parent-context__link {
	color: var(--color-white);
	font-family: var(--font-serif);
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.35;
}

@media (width >= 48rem) {
	.go-to-parent-context__title p,
	.go-to-parent-context__link {
		font-size: 1.5rem;
		line-height: 1.33;
	}

	.go-to-parent-context__title p {
		display: inline-flex;
		align-items: center;
	}
}

@media (width >= 90rem) {
	.go-to-parent-context__title p,
	.go-to-parent-context__link {
		font-size: 1.875rem;
	}
}

.go-to-parent-context__link:hover {
	text-decoration: none;
}
/* #endregion Title + Link */

/* #region Logo */
.go-to-parent-context__title__logo {
	background-image: url("../../images/jac-logo-inverted.svg");
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	margin: 0 4px -0.375rem 4px;
	width: 2.8125rem;
	height: 1.625rem;
}

@media (width >= 48rem) {
	.go-to-parent-context__title__logo {
		margin: 0 8px 0 8px;
		width: 3.6875rem;
		height: 2.125rem;
	}
}

@media (width >= 90rem) {
	.go-to-parent-context__title__logo {
		margin: 0 14px 0.125rem 13px;
		width: 4.6875rem;
		height: 2.75rem;
	}
}

/* #endregion Logo */

/* #region JAC */
.t-jac .go-to-parent-context {
	background-color: var(--color-blue);
}

.t-jac .go-to-parent-context__title__logo {
	background-image: url("../../images/caw-inverted.svg");
	margin: 0 4px -0.125rem 4px;
	width: 3.875rem;
	height: 1.125rem;
}

@media (width >= 48rem) {
	.t-jac .go-to-parent-context__title__logo {
		margin: 0 6px 0 10px;
	}
}

@media (width >= 90rem) {
	.t-jac .go-to-parent-context__title__logo {
		margin: 0 12px 0 12px;
		width: 6rem;
		height: 2rem;
	}
}

.t-jac .go-to-parent-context__link {
	color: var(--color-white);
}
/* #endregion JAC */
