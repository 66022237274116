.region-list {
	list-style: none;
	padding: 0;
}

.region-list a {
	color: var(--color-purple);
}

.region-list a:hover {
	color: var(--color-blue);
}

@media (width >= 48rem) {
	.flexible-content + .region-list.wrapper {
		margin-top: -1rem;
	}
}

@media (width >= 64rem) {
	.flexible-content + .region-list.wrapper {
		margin-top: -2rem;
	}
}

@media (width >= 86.25rem) {
	.flexible-content + .region-list.wrapper {
		margin-top: -3.625rem;
	}
}
