.embedded-media {
	background-color: var(--color-blue-grey);
	display: block;
	padding-bottom: 75%;
	position: relative;
	width: 100%;
}

.embedded-media.embedded-media--video {
	padding-bottom: 56.25%;
}

.embedded-media p {
	color: var(--color-grey);
	position: absolute;
	text-align: center;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
}

.embedded-media > *:not(p),
.embedded-media iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}
