.site-header {
	display: block;
}

@media (width < 64rem) {
	.site-header {
		background-color: var(--color-white);
		box-shadow: 0 0 8px 1px rgb(0 0 0 / 0.2);
		min-height: 3.5rem;
	}
}

.site-header__wrapper {
	display: grid;
}

.site-header__breadcrumb {
	display: none;
	order: 1;
}

@media (width >= 64rem) {
	.site-header__breadcrumb {
		background-color: var(--color-blue-light);
		display: block;
		min-height: 2.75rem;
	}
}

.site-header__meta {
	display: none;
	order: 2;
}

@media (width >= 64rem) {
	.site-header__meta {
		display: flex;
		justify-content: flex-end;
	}
}

.site-header__main {
	order: 3;
}

.site-header__main__inner {
	margin-inline: auto;
	position: relative;
	width: 100%;
	max-width: 1582px;
}

@media (width >= 64rem) {
	.site-header__main__inner {
		display: flex;
		margin-top: 2.75rem;
		margin-bottom: 2.75rem;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 94%;
		height: 4.5rem;
	}

	[data-has-breadcrumb] .site-header__main__inner {
		margin-top: 0;
	}
}

.site-header__menu-toggle {
	position: absolute;
	top: 0.3125rem;
	right: calc(3.75% - 0.625rem);
	width: 2.75rem;
	height: 2.75rem;
}

@media (width >= 48rem) {
	.site-header__menu-toggle {
		right: calc(3% - 0.625rem);
	}
}

@media (width >= 64rem) {
	.site-header__menu-toggle {
		display: none;
	}
}

.site-header__main__logo {
	margin-block: 1rem;
	position: absolute;
	top: 0;
	left: 3.75%;
	width: 5rem;
	height: 1.5rem;
}

@media (width >= 48rem) {
	.site-header__main__logo {
		left: 3%;
	}
}

@media (width >= 64rem) {
	.site-header__main__logo {
		margin-block: 0;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 6rem;
		height: 1.8rem;
	}
}

@media (width >= 86.25rem) {
	.site-header__main__logo {
		width: 7.5rem;
		height: 2.25rem;
	}
}

.t-jac .site-header__main__logo {
	margin-block: 0.5rem;
	width: 3.75rem;
	height: 2.625rem;
}

@media (width >= 64rem) {
	.t-jac .site-header__main__logo {
		margin-block: 0;
		width: 6rem;
		height: 4.1875rem;
	}
}

@media (width >= 86.25rem) {
	.t-jac .site-header__main__logo {
		width: 7.375rem;
		height: 5.125rem;
	}
}

.site-header__main__logo a {
	display: block;
}

.site-header__main__logo a img {
	display: block;
	width: 100%;
	height: auto;
}

.site-header__main__navigation {
	display: none;
}

@media (width >= 64rem) {
	.site-header__main__navigation {
		display: block;
	}
}
