:root {
	--color-background: var(--color-white);
	--color-black: #231f20;
	--color-blue: #1b5a9a;
	--color-blue-bright: #1d8dff;
	--color-blue-grey: #e1eaf4;
	--color-blue-light: #eef4fa;
	--color-blue-lightened: #cfdeeb;
	--color-foreground: var(--color-black);
	--color-grey: #767676;
	--color-grey-b: #6f6f6f;
	--color-grey-light: #dddddd;
	--color-orange-bright: #ff7f01; /* original orange */
	--color-orange: #ad5700;
	--color-orange-shaded: #944a00;
	--color-purple: #d70071;
	--color-purple-shaded: #c0006f;
	--color-white: #ffffff;

	/* fonts */
	--font-sans: "Open Sans", "open-sans", "Helvetica Neue", sans-serif;
	--font-serif: "Tenso", "tenso", "Open Sans", "open-sans", "Helvetica Neue", serif;

	/* header heights */
	--header-height: 3.5rem;
	--header-height-from-medium: 5.5625rem;

	/* z-indices */
	--z-index-modal-dialog: 1200;
}
