.grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
}

@supports (display: grid) {
	.grid {
		display: grid;
		grid-gap: 0.5em;
		justify-content: normal;
	}

	@media (width >= 35.5rem) {
		.grid {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (width >= 64rem) {
		.grid {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.grid__item {
	background-color: var(--color-white);
	box-sizing: border-box;
	flex-basis: 100%;
	margin-bottom: 0.5rem;
	overflow: hidden;
	padding: 1.25em;
	text-decoration: none;
}

@media (width >= 35.5rem) {
	.grid__item {
		flex-basis: calc(50% - 2.75rem);
		margin-right: 0.5rem;
	}
}

@media (width >= 64rem) {
	.grid__item {
		flex-basis: calc(33.3333% - 4.375rem);
		padding: 2rem;
	}
}

@media (width >= 86.25rem) {
	.grid__item {
		flex-basis: calc(33.3333% - 5.375rem);
		padding: 2.5rem;
	}
}

@media (35.5rem <= width < 64rem) {
	.grid__item:nth-child(2n + 2) {
		margin-right: 0;
	}
}

@media (width >= 64rem) {
	.grid__item:nth-child(3n + 3) {
		margin-right: 0;
	}
}

[data-text-align="center"] .grid__item {
	text-align: center;
}

.grid__item a {
	text-decoration: none;
}

@supports (display: grid) {
	.grid__item {
		flex-basis: auto;
		margin-right: 0;
		margin-bottom: 0;
	}
}

.grid__item__image {
	margin-top: -1.25rem;
	margin-bottom: 1rem;
	margin-left: -1.25rem;
	width: calc(100% + 2.5rem);
	max-width: none;
	height: auto;
}

@media (width >= 64rem) {
	.grid__item__image {
		margin-top: -2rem;
		margin-left: -2rem;
		width: calc(100% + 4rem);
	}
}

@media (width >= 86.25rem) {
	.grid__item__image {
		margin-top: -2.5rem;
		margin-bottom: 2.125rem;
		margin-left: -2.5rem;
		width: calc(100% + 5rem);
	}
}

.grid__item__title {
	color: var(--color-purple);
	font-family: var(--font-serif);
	font-size: 1rem;
	margin-top: 1rem;
	text-transform: uppercase;
	transition: color 96ms linear;
}

@media (width >= 86.25rem) {
	.grid__item__title {
		font-size: 1.125rem;
	}
}

.t-jac .grid__item__title {
	color: var(--color-orange);
}

.grid__item:hover .grid__item__title {
	color: var(--color-blue);
}

.grid__item__content {
	color: var(--color-black);
	display: inline-block;
	line-height: 1.5;
	margin-top: 1.5rem;
}

a .grid__item__content {
	text-decoration: none;
}

.grid__item__preview:not(.is-resolved) {
	display: none;
}

@media (width < 64rem) {
	.grid__item__preview {
		display: none;
	}
}

.grid__item--extra {
	background: transparent;
	border: 1px solid var(--color-blue);
	display: none;
	justify-content: center;
	align-items: center;
}

.grid__item--extra a {
	color: var(--color-blue);
	font-family: var(--font-serif);
	text-decoration: underline;
}

@media (35.5rem <= width < 64rem) {
	.grid__item--extra:nth-child(2n) {
		display: flex;
	}
}

@media (width >= 64rem) {
	.grid__item--extra:nth-child(3n),
	.grid__item--extra:nth-child(3n + 2) {
		display: flex;
	}
}
