.theme-grid {
	background-color: var(--color-blue-light);
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 48rem) {
	.theme-grid {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.theme-grid {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.theme-grid {
		padding-top: 5.625rem;
		padding-bottom: 5.625rem;
	}
}

.theme-grid__title {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-blue);
	font-family: var(--font-serif);
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.3333;
	margin-top: 0;
	margin-bottom: 2rem;
	text-align: center;
}

@media (width >= 48rem) {
	.theme-grid__title {
		font-size: 1.5rem;
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.theme-grid__title {
		margin-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.theme-grid__title {
		font-size: 1.875rem;
		margin-bottom: 5.625rem;
	}
}

.theme-grid--dark {
	background-color: var(--color-blue-grey);
}

/* #region Theme grid region */
.theme-grid__region {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 48rem) {
	.theme-grid__region {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.theme-grid__region {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.theme-grid__region {
		padding-top: 5.625rem;
		padding-bottom: 5.625rem;
	}
}

.theme-grid__region:first-child {
	padding-top: 0;
}

.theme-grid__region:last-child {
	padding-bottom: 0;
}

.theme-grid__region:not(:last-child) {
	border-bottom: 1px solid var(--color-blue-lightened);
}

.theme-grid__region__head {
	margin-bottom: 2rem;
}

@media (width >= 48rem) {
	.theme-grid__region__head {
		display: flex;
		margin-bottom: 3rem;
		justify-content: space-between;
		align-items: flex-end;
	}
}

@media (width >= 64rem) {
	.theme-grid__region__head {
		margin-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.theme-grid__region__head {
		margin-bottom: 5.625rem;
	}
}

.theme-grid__region__head h2 {
	margin-bottom: 0;
}

.theme-grid__region__link {
	color: var(--color-blue);
	margin-bottom: 0.3rem;
}
/* #endregion Theme grid region */

/* #region Theme grid region select */
@media (width < 64rem) {
	.theme-grid__region-select__item:first-child {
		padding-bottom: 2rem;
	}

	.theme-grid__region-select__item:last-child {
		border-top: 1px solid var(--color-blue-lightened);
		padding-top: 2rem;
	}
}

@media (width >= 64rem) {
	.theme-grid__region-select {
		display: flex;
	}

	.theme-grid__region-select__item {
		box-sizing: border-box;
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}

	.theme-grid__region-select__item:first-child {
		padding-right: 4rem;
	}

	.theme-grid__region-select__item:last-child {
		border-left: 1px solid var(--color-blue-lightened);
		padding-left: 4rem;
	}
}

@media (width >= 86.25rem) {
	.theme-grid__region-select__item:first-child {
		padding-right: 5.625rem;
	}

	.theme-grid__region-select__item:last-child {
		padding-left: 5.625rem;
	}
}

.theme-grid__region-select__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.theme-grid__region-select__list__item {
	display: inline-block;
	margin-bottom: 0.75rem;
}

a.theme-grid__region-select__list__link {
	border: 1px solid var(--color-blue);
	border-radius: 999999px;
	color: var(--color-blue);
	display: inline-block;
	font-family: var(--font-serif);
	padding: 0.875rem 1.875rem;
	text-decoration: none;
	transition:
		color 96ms linear,
		background-color 96ms linear;
}

.theme-grid__region-select__list__link:hover {
	background-color: var(--color-blue);
	color: var(--color-blue-grey);
}
/* #endregion Theme grid region select */

/* #region Theme grid anchor target */
.theme-grid__anchor-target {
	scroll-margin-top: var(--header-height);
}

@media (width >= 64rem) {
	.theme-grid__anchor-target {
		scroll-margin-top: var(--header-height-from-medium);
	}
}
/* #endregion Theme grid anchor target */
