.locations-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.locations-list__item {
	border-bottom: 1px solid var(--color-grey-light);
	margin-bottom: 1.5rem;
	padding-right: 3rem;
	padding-bottom: 2rem;
	position: relative;
}

.locations-list__item:last-child {
	border-bottom: 0;
	margin-bottom: 0;
	padding-bottom: 0;
}

.locations-list__item__title {
	color: var(--color-purple);
	font-size: 1.25rem;
	margin-bottom: 0.5rem;
}

.t-jac .locations-list__item__title {
	color: var(--color-orange);
}

.locations-list__item__title a {
	color: currentcolor;
	text-decoration: none;
}

.locations-list__item__title a:hover {
	text-decoration: underline;
}

.locations-list__item__content {
	margin-bottom: 0.5rem;
	max-width: 568px;
}

.locations-list__item__address {
	font-size: 0.875rem;
	font-weight: 600;
}
