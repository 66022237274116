.usp-group__title {
	text-align: center;
}

.usp-group__intro > p {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-blue);
	display: block;
	font-family: var(--font-serif);
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5;
	margin: 0 auto 2rem;
	text-align: center;
}

@media (width >= 48rem) {
	.usp-group__intro > p {
		font-size: 1.25rem;
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.usp-group__intro > p {
		font-size: 1.5rem;
		margin-bottom: 4rem;
		width: 66.6666%;
	}
}

@media (width >= 86.25rem) {
	.usp-group__intro > p {
		margin-bottom: 5.625rem;
	}
}

@media (width >= 35.5rem) {
	.usp-group__items {
		display: flex;
		flex-wrap: wrap;
		margin-right: -1rem;
		margin-left: -1rem;
		justify-content: space-between;
		align-items: stretch;
	}
}

@media (width >= 64rem) {
	.usp-group__items {
		flex-wrap: nowrap;
	}
}

.usp-group__item {
	margin: 0 0 1rem;
}

@media (width < 35.5rem) {
	.usp-group__item:last-child {
		margin-bottom: 0;
	}
}

@media (width >= 35.5rem) {
	.usp-group__item {
		flex-basis: calc(50% - 2rem);
		flex-grow: 1;
		margin: 0 1rem 2rem;
	}
}

@media (width >= 86.25rem) {
	.usp-group__item {
		flex-basis: 33.33%;
		margin-bottom: 0;
	}
}
