.mapboxgl-ctrl-bottom-right {
	display: none;
}

.mapboxgl-ctrl-bottom-left {
	display: none;
}

.mapboxgl-ctrl-group {
	background-color: transparent;
	border-radius: 0;
	box-shadow: none;
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in {
	background-image: none;
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in::before {
	color: var(--color-purple);
	content: "+";
	font-family: var(--font-sans);
	font-size: 1.5rem;
	font-weight: 600;
	transition: color 96ms linear;
}

.t-jac .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in::before {
	color: var(--color-orange);
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
	background-image: none;
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out::before {
	color: var(--color-purple);
	content: "-";
	font-family: var(--font-sans);
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1rem;
	transition: color 96ms linear;
}

.t-jac .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out::before {
	color: var(--color-orange);
}

.mapboxgl-ctrl-group > button + button {
	border-top: none;
	margin-top: 4px;
}

.mapboxgl-ctrl > button {
	background-color: var(--color-white);
	transition: background-color 96ms linear;
}

.mapboxgl-ctrl > button:hover {
	background-color: var(--color-purple);
}

.t-jac .mapboxgl-ctrl > button:hover {
	background-color: var(--color-orange);
}

.mapboxgl-ctrl > button:hover::before {
	color: var(--color-white);
}
