.carousel-container {
	display: grid;
}

.carousel {
	display: grid;
	grid-column: 1 / span 2;
	margin: 0;
	padding: 0;
	height: max-content;
}

.carousel__item {
	display: block;
	grid-row: 1;
	grid-column: 1;
	opacity: 0;
	position: relative;
	transition: opacity 256ms ease-in-out;
	width: 100%;
	z-index: 0;
}

.carousel__item[data-slide="current"] {
	opacity: 1;
	z-index: 1;
}

.carousel__item[data-slide="next"],
.carousel__item[data-slide="previous"] {
	pointer-events: none;
	z-index: 2;
}

.carousel__item__image {
	display: block;
	width: 100%;
	height: auto;
}

.carousel__item__caption {
	font-size: 0.875rem;
	margin-left: auto;
	padding-top: 0.5625rem;
	text-align: right;
	max-width: calc(100% - 10rem);
}

.carousel-pagination-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
}

.carousel-pagination-wrapper::before {
	aspect-ratio: 3/2;
	content: "";
	display: block;
}

.carousel-pagination {
	display: inline-flex;
	flex-wrap: wrap;
	padding-top: 0.4375rem;
	max-width: 10rem;
}

.carousel-pagination__slide-picker {
	display: block;
	padding: 0 0.25rem;
}

.carousel-pagination__slide-picker button {
	-mrh-resets: button;
	font-size: 0.875rem;
}

[data-matches] > .carousel-pagination__slide-picker button {
	text-decoration: underline;
}

.carousel-counter {
	color: var(--color-black);
	font-size: 0.875rem;
	line-height: 1;
	margin-block-start: 0.75rem;
	padding-block: 2px;
}

.carousel-controls {
	font-size: 0.875rem;
	line-height: 1;
	margin-block-start: 0.75rem;
	text-align: right;
}

.carousel-controls__button {
	-mrh-resets: button;
	font-family: var(--font-sans);
	font-size: 0.875rem;
	line-height: 1;
	margin-inline-start: 0.625rem;
	padding-block: 2px;
	text-decoration: underline;
	transition: color 96ms linear;
}

@media (hover: hover) {
	.carousel-controls__button:hover {
		color: var(--color-purple);
		cursor: pointer;
	}

	.t-jac .carousel-controls__button:hover {
		color: var(--color-orange);
	}
}
