.page-anchor {
	margin-top: -120px;
	padding-top: 120px;
	position: absolute;
	left: -40px;
	width: 20px;
}

.page-anchor a {
	display: block;
	width: 20px;
	height: 20px;
}

.page-anchor a .icon-link {
	width: 20px;
	height: 20px;
}
