.filter__list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0.875rem;
}

@media (width < 48rem) {
	.filter__list {
		flex-wrap: wrap;
		justify-content: center;
	}
}

@media (width >= 48rem) {
	.filter__list {
		padding: 1.375em 0;
	}
}

.filter__item {
	color: var(--color-blue);
	flex-basis: 0;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 0.875rem;
	font-weight: 600;
	text-align: center;
}

@media (width >= 48rem) {
	.filter__item {
		border-left: 1px solid var(--color-blue-lightened);
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (width >= 64rem) {
	.filter__item {
		font-size: 1.0625rem;
	}
}

.filter__item:first-child {
	border-left: none;
}

.filter__item a {
	color: currentcolor;
	display: block;
	padding: 0.5rem;
	text-decoration: none;
}

.filter__item a:hover,
.filter__item a.is-active {
	color: var(--color-purple);
}

.t-jac .filter__item a:hover,
.t-jac .filter__item a.is-active {
	color: var(--color-orange);
}

.filter__item a:hover {
	text-decoration: underline;
}

.filter__item:first-child {
	border-left: none;
}

.filter__item:first-child span {
	border-left: none;
}

.filter__item--active {
	color: var(--color-purple);
}

.t-jac .filter__item--active {
	color: var(--color-orange);
}
