.ui-datepicker {
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
	cursor: pointer;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
	float: right;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
	margin-top: 1rem;
}

.ui-datepicker .ui-datepicker-month,
.ui-datepicker .ui-datepicker-year {
	appearance: none;
	background-color: var(--color-white);
	background-image: url("../../images/dropdown-arrow.svg");
	background-position: calc(100% - 0.5rem) center;
	background-repeat: no-repeat;
	border: 1px solid var(--color-grey-light);
	border-radius: 0;
	box-sizing: border-box;
	font-family: var(--font-sans);
	font-size: 1rem;
	margin-right: 0.25rem;
	padding: 0.25rem 1.5rem 0.25rem 0.25rem;
}

.ui-datepicker .ui-datepicker-month:focus-visible,
.ui-datepicker .ui-datepicker-year:focus-visible {
	border-color: var(--color-blue);
	box-shadow: 0 0 2px var(--color-blue);
	outline: 0;
}

@media (width < 48rem) {
	.ui-datepicker-calendar td,
	.ui-datepicker-calendar th {
		padding: 0.4rem;
	}
}
