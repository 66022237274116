.call-to-action {
	display: flex;
	flex-direction: row;
}

@media (width < 64rem) {
	.call-to-action {
		flex-wrap: wrap;
	}
}

@media (width >= 64rem) {
	.call-to-action {
		align-items: stretch;
	}
}

.call-to-action__image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	flex: 0 0 100%;
	position: relative;
}

@media (width < 64rem) {
	.call-to-action__image {
		padding-bottom: 58%;
	}

	.js-loading-error .call-to-action__image,
	.no-js .call-to-action__image {
		padding-bottom: 0;
	}
}

@media (width >= 64rem) {
	.call-to-action__image {
		flex-basis: 55%;
	}
}

@media (width >= 86.25rem) {
	.call-to-action__image {
		flex-basis: 66%;
	}
}

.call-to-action__content {
	background-color: var(--color-orange);
	box-sizing: border-box;
	color: var(--color-white);
	padding: 2em 1.25em;
}

@media (width >= 64rem) {
	.call-to-action__content {
		flex-grow: 1;
		padding: 3em 2.5em;
	}
}

@media (width >= 86.25rem) {
	.call-to-action__content {
		padding: 4em 3.75em;
	}
}

.call-to-action__title {
	color: currentColor;
	font-size: 1.25rem;
	line-height: 1.33;
	margin-bottom: 2rem;
}

@media (width >= 86.25rem) {
	.call-to-action__title {
		font-size: 1.5rem;
		margin-bottom: 3rem;
	}
}

@media (width >= 105rem) {
	.call-to-action__title {
		font-size: 1.875rem;
	}
}

.call-to-action__button {
	background-color: var(--color-white);
	color: var(--color-orange);
	margin-top: 2em;
}

@media (width >= 86.25rem) {
	.call-to-action__button {
		margin-top: 3rem;
	}
}

.t-jac .call-to-action__button {
	background-color: var(--color-blue);
	color: var(--color-white);
}

@media (hover: hover) {
	.call-to-action__button:hover {
		color: var(--color-blue);
	}

	.t-jac .call-to-action__button:hover {
		color: var(--color-orange);
	}
}
