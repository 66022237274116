@media (width >= 64rem) {
	.call-to-action-group {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
	}
}

.call-to-action-group .call-to-action-group__item {
	margin-top: 1rem;
}

@media (width >= 48rem) {
	.call-to-action-group .call-to-action-group__item {
		margin-top: 3rem;
	}
}

@media (width >= 64rem) {
	.call-to-action-group .call-to-action-group__item {
		margin: 0;
		width: 31%;
	}
}

.call-to-action-group .call-to-action-group__item:first-child {
	margin-top: 0;
}
