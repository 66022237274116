/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
body {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

@media (width < 64rem) {
	body {
		overflow-x: hidden;
	}
}

body.is-showing-modal-dialog {
	overflow: hidden;
}

body.t-404 {
	background-color: var(--color-blue-light);
	padding-top: 0;
}

@media (width < 64rem) {
	body.has-contact-picker {
		padding-bottom: 64px;
	}
}

@media (width < 64rem) {
	.js-loading-error body,
	.no-js body {
		padding-top: 0;
	}
}

main {
	display: block;
	position: relative;
}

img {
	width: 100%;
	max-width: 100%;
}

table {
	border-collapse: collapse;
	font-size: 1rem;
	margin: 2rem 0;
	width: 100%;
	max-width: 100%;
}

@media (width >= 48rem) {
	table {
		margin: 3rem 0;
	}
}

th,
td {
	font-weight: 400;
	padding: 1rem;
	text-align: left;
}

@media (width >= 48rem) {
	th,
	td {
		padding: 1rem 1.875rem;
	}
}

table,
th,
td {
	border: 1px solid var(--color-grey-light);
}

/* thead th,
tfoot td {
	background-color: var(--color-blue);
	color: var(--color-white);
}

tbody tr {
	background-color: var(--color-blue-light);

	&:nth-child(even) {
		background-color: var(--color-blue-lightened);
	}
} */

hr {
	background-color: var(--color-grey-light);
	border: none;
	height: 1px;
}

/* MISTAKE : imgages just worked and then we decided to re-do this with JS */

/* This sets a high contrast background color when these images will not load */
.js-loading-error [mr-imgix][mr-imgix-critical],
.no-js [mr-imgix][mr-imgix-critical] {
	background-color: var(--color-blue);
}

.js-loading-error .t-jac [mr-imgix][mr-imgix-critical],
.no-js .t-jac [mr-imgix][mr-imgix-critical] {
	background-color: var(--color-orange);
}

/* #region Hyphens */
:is(h1, h2, h3, h4, h5, h6) {
	hyphens: auto;
	text-wrap: balance;
}

@media (width < 35.5rem) {
	li:not([class]) {
		hyphens: auto;
	}
}

li,
p {
	text-wrap: pretty;
}
/* #endregion Hyphens */
