@media (width < 64rem) {
	.side-navigation {
		display: none;
	}
}

.side-navigation.side-navigation--mobile {
	display: block;
}

@media (width >= 64rem) {
	.side-navigation.side-navigation--mobile {
		display: none;
	}
}

.side-navigation__list {
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0 3.75% 2rem;
}

@media (48rem <= width < 64rem) {
	.side-navigation__list {
		margin: 0 auto;
		padding-right: 0;
		padding-left: 0;
		width: 66.6666%;
	}
}

@media (width >= 64rem) {
	.side-navigation__list {
		padding: 0 0 0 3vw;
		position: absolute;
		top: -0.5rem;
		left: 0;
		width: calc(13.6666vw);
		z-index: 3;
	}
}

@media (width >= 104.75rem) {
	.side-navigation__list {
		padding-left: calc((100vw - 98.875rem) / 2);
		width: auto;
	}
}

.side-navigation__link {
	color: var(--color-blue);
	display: block;
	font-family: var(--font-serif);
	font-weight: 500;
	text-decoration: none;
}

@media (width < 64rem) {
	.side-navigation__link {
		border-bottom: 1px solid rgb(27 91 154 / 0.25);
		box-sizing: border-box;
		font-size: 0.9375rem;
		line-height: 2.5rem;
		padding: 0.25rem 0;
		position: relative;
	}
}

@media (width >= 64rem) {
	.side-navigation__link {
		display: inline-block;
		font-size: 1rem;
		padding-top: 0.375rem;
		padding-bottom: 0.375rem;
		width: 100%;
	}
}

@media (width >= 86.25rem) {
	.side-navigation__link {
		font-size: 1.125rem;
	}
}

@media (width < 64rem) {
	.side-navigation__item:first-child .side-navigation__link {
		border-top: 1px solid rgb(27 91 154 / 0.25);
	}
}

@media (width < 64rem) {
	.side-navigation__link::after {
		content: "›";
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
	}
}

.side-navigation__link:hover,
.side-navigation__link.side-navigation__link--active {
	color: var(--color-purple);
}

.t-jac .side-navigation__link:hover,
.t-jac .side-navigation__link.side-navigation__link--active {
	color: var(--color-orange);
}
