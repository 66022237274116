.location-pagination {
	display: flex;
	margin-top: 2rem;
	margin-right: auto;
	margin-left: auto;
	justify-content: space-between;
	position: relative;
	width: 92.5%;
	max-width: 672px;
}

@media (width >= 48rem) {
	.location-pagination {
		width: 66.6666%;
	}
}

@media (width >= 64rem) {
	.location-pagination {
		margin-top: 3rem;
		width: 83.3333%;
		max-width: 1400px;
	}
}

.location-pagination.location-pagination--map {
	width: 100%;
}

.location-pagination__button.location-pagination__button--disabled {
	opacity: 0.3;
	pointer-events: none;
}
