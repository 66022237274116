.questions__group {
	border-top: 1px solid var(--color-grey);
	padding: 1rem 0;
}

@media (width >= 64rem) {
	.questions__group {
		padding: 3rem 0;
	}
}

.questions__group:first-child {
	border-top: 0;
	padding-top: 0;
}

.questions__group:last-child {
	padding-bottom: 0;
}
