.overview {
	background-color: var(--color-white);
}

.overview__filter {
	background-color: var(--color-blue-light);
}

.overview__grid {
	padding: 3rem 0;
}

.overview-pagination {
	display: flex;
	gap: 2rem;
	justify-content: center;
}
