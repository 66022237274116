.table th,
.table td {
	line-height: 1.75;
	vertical-align: top;
}

@media (width < 48rem) {
	.table th,
	.table td {
		display: block;
		font-size: 0.875rem;
		padding-top: 0.5625rem;
		padding-bottom: 0.5625rem;
	}
}

.table th {
	font-weight: 600;
}
