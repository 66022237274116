.region-info {
	border-top: 1px solid var(--color-grey-light);
	border-bottom: 1px solid var(--color-grey-light);
	color: var(--color-purple);
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 48rem) {
	.region-info {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

.t-jac .region-info {
	color: var(--color-orange);
}

.region-info__title {
	color: currentColor;
	line-height: 1.75;
	margin: 0;
}
