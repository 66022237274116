.campaign-banner {
	background-color: var(--color-blue-light);
	overflow: hidden;
	padding-top: 2rem;
	padding-bottom: 2rem;
	text-align: center;
}

@media (width >= 48rem) {
	.campaign-banner {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.campaign-banner {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.campaign-banner {
		padding-top: 5.625rem;
		padding-bottom: 5.625rem;
	}
}

.campaign-banner__illustration {
	display: block;
	margin: 0 -50% 1rem;
	width: 200%;
	max-width: none;
	height: auto;
}

@media (width >= 35.5rem) {
	.campaign-banner__illustration {
		margin: 0 -25% 2rem;
		width: 150%;
	}
}

@media (width >= 48rem) {
	.campaign-banner__illustration {
		margin: 0 -10% 2rem;
		width: 120%;
	}
}

@media (width >= 64rem) {
	.campaign-banner__illustration {
		margin: 0 0 2rem;
		width: 100%;
	}
}

@media (width >= 86.25rem) {
	.campaign-banner__illustration {
		margin-bottom: 3rem;
	}
}

.campaign-banner__title {
	font-size: 1.875rem;
	line-height: 1.33;
	margin-bottom: 1rem;
}

@media (width >= 48rem) {
	.campaign-banner__title {
		font-size: 2rem;
		margin-bottom: 2rem;
	}
}

@media (width >= 86.25rem) {
	.campaign-banner__title {
		font-size: 3rem;
		margin-bottom: 3rem;
	}
}

.campaign-banner__button {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	appearance: none;
	background: none;
	background-color: var(--color-purple);
	border: 0;
	box-shadow: none;
	color: var(--color-white);
	display: inline-block;
	font-size: 1.125rem;
}

.t-jac .campaign-banner__button {
	background-color: var(--color-orange);
}

@media (width >= 48rem) {
	.campaign-banner__button {
		font-size: 1.25rem;
	}
}

@media (width >= 64rem) {
	.campaign-banner__button {
		font-size: 1.5rem;
	}
}

.campaign-banner__button:hover {
	cursor: pointer;
}

.campaign-banner__button-wrapper {
	display: none;
}

@media (width >= 64rem) {
	.campaign-banner__button-wrapper {
		display: block;
	}
}

@media (width >= 64rem) {
	.campaign-banner__link {
		display: none;
	}
}
