.locator__step {
	position: relative;
}

.locator__step__anchor {
	position: absolute;
	top: -100px;
	left: 0;
}

@media (width >= 64rem) {
	.locator__step__anchor {
		top: -120px;
	}
}

@media (width >= 48rem) {
	.locator__step__title::after {
		content: " (optioneel)";
	}
}

@media (width < 48rem) {
	.locator__step__title::before {
		border: 1px solid var(--color-blue);
		top: 3px;
		width: 14px;
		height: 14px;
	}
}

@media (width >= 48rem) {
	.locator__step__title::before {
		content: none;
		display: none;
	}
}

.locator__types__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.locator__types__list button {
	appearance: none;
	background: none;
	border: none;
	box-shadow: none;
	cursor: pointer;
	display: block;
	font-family: var(--font-serif);
	font-size: 1.125rem;
	line-height: 1.75rem;
	text-align: left;
	text-transform: uppercase;
	width: 100%;
}

.locator__types__list button:hover {
	color: var(--color-purple);
}

.locator__types__group {
	background-color: var(--color-blue-light);
	margin-bottom: 0.5rem;
}

.locator__types__group__title {
	background-color: rgb(0 0 0 / 0);
	color: var(--color-purple);
	cursor: pointer;
	line-height: 1.25rem;
	padding: 0.875rem 1rem 0.875rem 1rem;
	position: relative;
	transition: background-color 96ms linear;
}

.locator__types__group__title:hover {
	background-color: rgb(0 0 0 / 0.045);
	color: var(--color-purple);
}

.locator__types__group__toggle:checked ~ .locator__types__group .locator__types__group__title {
	color: var(--color-black);
}

.locator__types__group.is-open .locator__types__group__title::before {
	content: "-";
}

.locator__types__group__content {
	color: var(--color-black);
	list-style: none;
	margin: 0;
	padding: 0.5rem 1rem 1.375rem 2.5rem;
}

@media (width >= 64rem) {
	.locator__types__group__content {
		padding-left: 3.5rem;
	}
}

.locator__types__group__content :any-link[data-is-active] {
	font-weight: 600;
	text-decoration-thickness: 2px;
}

.locator__types__group__content :has(> :any-link[data-is-active])::before {
	color: var(--color-purple);
	content: " - ";
	font-weight: 600;
}
