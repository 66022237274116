.colored-section {
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 48rem) {
	.colored-section {
		margin-top: 3rem;
		margin-bottom: 3rem;
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.colored-section {
		margin-top: 4rem;
		margin-bottom: 4rem;
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.colored-section {
		margin-top: 5.625rem;
		margin-bottom: 5.625rem;
		padding-top: 5.625rem;
		padding-bottom: 5.625rem;
	}
}

.colored-section + .colored-section {
	margin-top: -2rem;
}

@media (width >= 48rem) {
	.colored-section + .colored-section {
		margin-top: -3rem;
	}
}

@media (width >= 64rem) {
	.colored-section + .colored-section {
		margin-top: -4rem;
	}
}

@media (width >= 86.25rem) {
	.colored-section + .colored-section {
		margin-top: -5.625rem;
	}
}

*.colored-section:first-of-type,
.theme-grid + .colored-section,
.flexible-content + .colored-section {
	margin-top: 0;
}

*.colored-section:last-of-type {
	margin-bottom: 0;
}

.locator .colored-section {
	padding-top: 0;
	padding-bottom: 0;
}

.colored-section--blue {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.colored-section--blue-grey {
	background-color: var(--color-blue-grey);
}

.colored-section--blue-light {
	background-color: var(--color-blue-light);
}

.location-overview .colored-section--blue-light:nth-child(even) {
	background-color: var(--color-white);
}

.colored-section--blue-lightened {
	background-color: var(--color-blue-lightened);
}
