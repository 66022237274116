.copy {
	position: relative;
}

.copy__button {
	position: relative;
	z-index: 2;
}

.copy__content {
	background: 0;
	border: 0;
	box-shadow: none;
	display: block;
	opacity: 0;
	outline: 0;
	overflow: hidden;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1px;
	height: 1px;
	z-index: 1;
}

.copy__tooltip {
	background-color: var(--color-white);
	border: 1px solid var(--color-grey-light);
	box-sizing: border-box;
	color: var(--color-grey);
	display: none;
	font-size: 0.875rem;
	padding: 7px 12px;
	position: absolute;
	top: 50%;
	left: 100%;
	transform: translateY(-50%);
	width: max-content;
}

.copy__tooltip.is-visible {
	display: block;
}
