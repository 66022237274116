.social-accounts {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	align-items: center;
}

.footer .social-accounts {
	justify-content: center;
}

.social-accounts__item {
	margin-right: 1.125rem;
}

.social-accounts__link .icon {
	display: block;
	fill: var(--color-blue);
	transition: fill 96ms linear;
	width: 1.125rem;
	height: 1.125rem;
}

.footer .social-accounts__link .icon {
	fill: var(--color-grey);
}

@media (width >= 64rem) {
	.social-accounts__link:hover .icon {
		fill: var(--color-purple);
	}
}

@media (width >= 64rem) {
	.t-jac .social-accounts__link:hover .icon {
		fill: var(--color-orange);
	}
}

@media (width >= 64rem) {
	.footer .social-accounts__link:hover .icon {
		fill: var(--color-blue);
	}
}
