.round-thumbnails {
	background-color: var(--color-blue-light);
	padding-top: 2rem;
}

@media (width >= 48rem) {
	.round-thumbnails {
		padding-top: 3rem;
		padding-bottom: 1rem;
	}
}

@media (width >= 64rem) {
	.round-thumbnails {
		padding-top: 4rem;
	}
}

@media (width >= 86.25rem) {
	.round-thumbnails {
		padding-top: 5.625rem;
		padding-bottom: 1.625rem;
	}
}

@media (width >= 35.5rem) {
	.round-thumbnails__grid {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: flex-start;
	}
}

@media (width >= 48rem) {
	.round-thumbnails__grid {
		margin-right: -1.5rem;
		margin-left: -1.5rem;
	}
}

@media (width >= 64rem) {
	.round-thumbnails__grid {
		margin-right: -2rem;
		margin-left: -2rem;
	}
}

@media (width >= 86.25rem) {
	.round-thumbnails__grid {
		margin-right: -2rem;
		margin-left: -2rem;
	}
}

.round-thumbnails__grid__item {
	margin-right: 1rem;
	margin-left: 1rem;
	padding-bottom: 2rem;
}

@media (width >= 35.5rem) {
	.round-thumbnails__grid__item {
		flex-basis: calc(50% - 2rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (width >= 48rem) {
	.round-thumbnails__grid__item {
		flex-basis: calc(33.3333% - 3rem);
		margin-right: 1.5rem;
		margin-left: 1.5rem;
	}
}

@media (width >= 64rem) {
	.round-thumbnails__grid__item {
		flex-basis: calc(33.3333% - 4rem);
		margin-right: 2rem;
		margin-left: 2rem;
		padding-bottom: 3rem;
	}
}

@media (width >= 86.25rem) {
	.round-thumbnails__grid__item {
		flex-basis: calc(25% - 4rem);
		margin-right: 2rem;
		margin-left: 2rem;
		padding-bottom: 4rem;
	}
}

.round-thumbnail__title {
	font-family: var(--font-serif);
	font-size: 1.125rem;
	font-weight: 500;
}

@media (width >= 48rem) {
	.round-thumbnail__title {
		font-size: 1.25rem;
	}
}
