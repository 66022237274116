.doormat {
	--color-purple: rgb(196 0 102);
}

@media (width >= 64rem) {
	.doormat {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.doormat {
		padding-top: 5.625rem;
		padding-bottom: 5.625rem;
	}
}

.doormat__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (width >= 64rem) {
	.doormat__list {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (width >= 64rem) {
	.doormat__item {
		flex-basis: 25%;
		flex-grow: 0;
		flex-shrink: 1;
		margin: 0;
	}
}

@media (width < 64rem) {
	.doormat__item.doormat__item--active {
		background-color: var(--color-purple);
	}
}

.doormat__title {
	display: inline-block;
	margin-bottom: 0.125rem;
	text-decoration: none;
}

@media (width < 64rem) {
	.doormat__title {
		display: block;
		overflow: hidden;
		padding-top: 0.5rem;
		position: relative;
	}
}

@media (width >= 64rem) {
	.doormat__title {
		margin-bottom: 1rem;
	}
}

@media (width < 64rem) {
	.doormat__item--active .doormat__title {
		border-bottom-color: transparent;
	}
}

.doormat__title__label {
	color: var(--color-blue);
	font-family: var(--font-serif);
	font-size: 1rem;
	font-weight: 400;
	position: relative;
	z-index: 1;
}

@media (width < 64rem) {
	.doormat__title__label {
		box-sizing: border-box;
		display: block;
		padding: 0.75rem 0 0.75rem 3.75%;
	}
}

@media (48rem <= width < 64rem) {
	.doormat__title__label {
		padding: 0.75rem 0 0.75rem 3%;
	}
}

@media (width >= 64rem) {
	.doormat__title__label {
		font-size: 1.125rem;
		font-weight: 500;
	}
}

@media (width >= 64rem) {
	.doormat__title__label:hover {
		font-size: 1.125rem;
		font-weight: 500;
	}
}

.doormat__title:hover .doormat__title__label {
	color: var(--color-purple);
}

@media (width >= 64rem) {
	.doormat__item--active .doormat__title .doormat__title__label {
		color: var(--color-purple);
	}
}

@media (width < 64rem) {
	.doormat__item--active .doormat__title .doormat__title__label {
		color: var(--color-white);
	}
}

.doormat__submenu {
	font-size: 0.875rem;
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (width < 64rem) {
	.doormat__submenu {
		border-bottom: 1px solid rgb(27 91 154 / 0.25);
		box-sizing: border-box;
		margin-top: 0.25rem;
		padding: 0 3.75% 1.5rem;
	}
}

@media (width < 64rem) {
	.doormat__item--active .doormat__submenu {
		background-color: var(--color-purple);
	}
}

@media (48rem <= width < 64rem) {
	.doormat__submenu {
		padding: 0 3% 0.5rem;
	}
}

.doormat__submenu__item {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

@media (width >= 64rem) {
	.doormat__submenu__item {
		margin-top: 0.125rem;
		margin-bottom: 0.125rem;
	}
}

.doormat__submenu__item a {
	color: var(--color-blue);
	display: inline-block;
	line-height: 1.5;
	padding: 0.25rem 0;
	text-decoration: none;
	text-wrap: pretty;
	max-width: min(35ch, 90%);
}

@media (width < 64rem) {
	.doormat__submenu__item a {
		display: block;
		padding-left: 1.125rem;
		position: relative;
	}
}

.doormat__submenu__item a:hover {
	color: var(--color-purple);
}

@media (width < 64rem) {
	.doormat__item--active .doormat__submenu__item a {
		color: var(--color-white);
	}
}

@media (width >= 64rem) {
	.doormat__submenu__item.doormat__submenu__item--active a {
		color: var(--color-purple);
	}
}

@media (width < 64rem) {
	.doormat__submenu__item--active a::before {
		background-color: var(--color-white);
		border-radius: 6px;
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 6px;
		height: 6px;
	}
}
