.search-form {
	text-align: center;
}

.search-form__title {
	margin-bottom: 1rem;
}

.search-form__form {
	margin-top: 2rem;
}

@media (width >= 64rem) {
	.search-form__form {
		margin-top: 3rem;
	}
}

@media (width >= 86.25rem) {
	.search-form__form {
		margin-top: 3.875rem;
	}
}

.search-form__label {
	display: inline-block;
	margin-bottom: 1rem;
}

.search-form__input {
	border: 0;
	box-sizing: border-box;
	font-size: 1.125rem;
	line-height: 4rem;
	margin-bottom: 1rem;
	text-align: center;
	width: 100%;
	height: 4rem;
}

@media (width >= 64rem) {
	.search-form__input {
		font-family: var(--font-sans);
		font-weight: 400;
		line-height: 5.3125rem;
		margin-bottom: 2.5rem;
		height: 5.3125rem;
	}
}

.search-form__input::placeholder {
	color: var(--color-grey-light);
}

.search-form__input:active,
.search-form__input:focus {
	border: 1px solid var(--color-grey-light);
	outline: 0;
}

.search-form__submit {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-blue);
	color: var(--color-white);
	font-size: 1rem;
	font-weight: 400;
	margin: 0 auto;
}

@media (width >= 48rem) {
	.search-form__submit {
		font-size: 1.25rem;
	}
}

@media (width < 64rem) {
	.search-form__submit {
		width: 100%;
	}
}

@media (width >= 86.25rem) {
	.search-form__submit {
		font-size: 1.5rem;
	}
}

@media (hover: hover) {
	.search-form__submit:hover {
		color: var(--color-purple);
		cursor: pointer;
	}

	.t-jac .search-form__submit:hover {
		color: var(--color-orange);
	}
}
