/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.search-results__list:not(:only-child):not(:last-child) {
	margin-bottom: 2rem;
}

@media (width >= 48rem) {
	.search-results__list:not(:only-child):not(:last-child) {
		margin-bottom: 3rem;
	}
}

@media (width >= 64rem) {
	.search-results__list:not(:only-child):not(:last-child) {
		margin-bottom: 4rem;
	}
}

@media (width >= 86.25rem) {
	.search-results__list:not(:only-child):not(:last-child) {
		margin-bottom: 5.625rem;
	}
}

.search-results__label {
	display: inline;
}

.search-results__label::after {
	background-color: var(--color-blue);
	border-radius: 10px;
	box-sizing: border-box;
	color: var(--color-white);
	content: attr(parent-context);
	display: inline-block;
	font-size: 0.625rem;
	margin-left: 10px;
	padding: 1px 6px;
	text-transform: uppercase;
}

.search-results__label[parent-context="jac"]::after {
	background-color: var(--color-orange);
}
